<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Emissão de boletos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gestão de
                  <span>recebíveis</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
            </div>
          </div>
        </div>
        <!-- /fd-app-welcome -->
        <section class="container">
          <!-- Conteúdo principal -->
          <div class="card-body">                      
            
            <div class="row">
              <transition name="slide-fade">
              <div v-if="fastNavegacao.exibirBoletos" class="col-12">
                
                <div class="row">
                  <div v-if="!fastInvoice.loading" class="col-12">
                      <div class="row">
                        <div class="col-12">
                          <h4 class="text-secondary">Períodos</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                          <label>Ano vencimento:</label>
                          <select :class="'form-control'" v-model="fastFiltro.anoVencimento.escolhido" @change="fastFiltro.data_alterada = true">
                            <option v-for="(ano, index) in fastFiltro.anoVencimento.lista" :key="index" :value="ano" >
                              {{ano}}
                            </option>
                          </select>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                          <label>Mês vencimento:</label>
                          <select :class="'form-control'" v-model="fastFiltro.mesVencimento.escolhido" @change="fastFiltro.data_alterada = true">
                            <option v-for="(mes, index) in fastFiltro.mesVencimento.lista" :key="index" :value="index" >
                              {{mes}}
                            </option>
                          </select>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                          <label>Ano registro:</label>
                          <select :class="'form-control'" v-model="fastFiltro.anoRegistro.escolhido" @change="fastFiltro.data_alterada = true">
                            <option v-for="(ano, index) in fastFiltro.anoRegistro.lista" :key="index" :value="ano" >
                              {{ano}}
                            </option>
                          </select>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                          <label>Mês registro:</label>
                          <select :class="'form-control'" v-model="fastFiltro.mesRegistro.escolhido" @change="fastFiltro.data_alterada = true">
                            <option v-for="(mes, index) in fastFiltro.mesRegistro.lista" :key="index" :value="index" >
                              {{mes}}
                            </option>
                          </select>
                        </div>

                        <div v-if="fastFiltro.data_alterada" class="col-12 mt-4 text-left">
                          <button class="btn btn-sm btn-primary blink_me" @click="alteraDataFiltro()">
                            Alterar período
                          </button>
                        </div>

                      </div>
                    </div>

                  <div class="col-12 mt-4">
                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <p class="text-secondary">
                           
                          <span v-if="fastStatusFinanceiro.plataformas.length > 1">
                            Dados referente às plataformas {{retornaNomePlataformas()}}
                          </span>
                          <span v-else-if="fastStatusFinanceiro.plataformas.length == 1">
                            Dados referente à plataforma {{fastStatusFinanceiro.plataformas[0].nome_plataforma}}
                          </span>
                        </p>
                      </div>              
                    </div>
                    
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="card border-left-primary shadow h-100">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <div
                                  class="text-xs font-weight-bold text-secondary text-uppercase mb-1 text-center"
                                >
                                  Total Cobrado
                                  <div v-if="fastStatusFinanceiro.loading">
                                    <small>
                                      <b-icon
                                        icon="gear-fill"
                                        animation="spin"
                                      /> Calculando
                                    </small>                            
                                  </div>
                                  <div v-else>
                                    R$ {{formataPreco(fastStatusFinanceiro.totalCobrado)}}
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="card border-left-primary shadow h-100">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <div
                                  class="text-xs font-weight-bold text-success text-uppercase mb-1 text-center"
                                >
                                  Total Pago
                                  <div v-if="fastStatusFinanceiro.loading">
                                    <small>
                                      <b-icon
                                        icon="gear-fill"
                                        animation="spin"
                                      /> Calculando
                                    </small>                            
                                  </div>
                                  <div v-else>
                                    R$ {{formataPreco(fastStatusFinanceiro.totalPago)}}
                                  </div>    
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="card border-left-primary shadow h-100">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <div
                                  class="text-xs font-weight-bold text-warning text-uppercase mb-1 text-center"
                                >
                                  A vencer
                                  <div v-if="fastStatusFinanceiro.loading">
                                    <small>
                                      <b-icon
                                        icon="gear-fill"
                                        animation="spin"
                                      /> Calculando
                                    </small>                            
                                  </div>
                                  <div v-else>
                                    R$ {{formataPreco(fastStatusFinanceiro.totalVencer)}}
                                  </div>                           
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="card border-left-primary shadow h-100">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <div
                                  class="text-xs font-weight-bold text-danger text-uppercase mb-1 text-center"
                                >
                                  Vencido
                                  <div v-if="fastStatusFinanceiro.loading">
                                    <small>
                                      <b-icon
                                        icon="gear-fill"
                                        animation="spin"
                                      /> Calculando
                                    </small>                            
                                  </div>
                                  <div v-else>
                                    R$ {{formataPreco(fastStatusFinanceiro.totalVencido)}}
                                  </div>                           
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  

                <div class="row mt-4">
                  <div v-if="!fastInvoice.loading" class="col-12">
                    <div class="row">    

                      <div class="col-12 mt-4">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4 text-right">
                            <button
                              v-if="!exibeFiltro"
                              class="btn btn-sm btn-secondary ml-2"
                              @click="exibeFiltro = true"
                            >
                              <small><b-icon icon="eye-fill"></b-icon> Exibir filtros</small>                      
                            </button>
                            <button
                              v-else
                              class="btn btn-sm btn-secondary ml-2"
                              @click="exibeFiltro = false"
                            >
                              <small><b-icon icon="eye-slash-fill"></b-icon> Ocultar filtros</small>    
                            </button>
                            <button class="btn btn-sm btn-primary ml-2" @click="exibeModalEmitirBoleto()">
                              <b-icon icon="file-arrow-up"></b-icon> Emitir fatura
                            </button>
                          </div>

                          <transition name="slide-fade">
                          <div v-if="exibeFiltro" class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                <label>Plataforma:</label>
                                <select :class="fastFiltro.plataforma.escolhida ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.plataforma.escolhida" @change="aplicaFiltro()">
                                  <option value="">Todas</option>
                                  <option v-for="(nome_plataforma, index) in fastFiltro.plataforma.lista" :key="index" :value="nome_plataforma" >
                                    {{nome_plataforma}}
                                  </option>
                                </select>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                <label>Aluno:</label>
                                <select :class="fastFiltro.aluno.escolhido ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.aluno.escolhido" @change="aplicaFiltro()">
                                  <option value="">Todos</option>
                                  <option v-for="(nome_aluno, index) in fastFiltro.aluno.lista" :key="index" :value="nome_aluno" >
                                    {{nome_aluno}}
                                  </option>
                                </select>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                <label>Responsavel:</label>
                                <select :class="fastFiltro.responsavel.escolhido ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.responsavel.escolhido" @change="aplicaFiltro()">
                                  <option value="">Todos</option>
                                  <option v-for="(nome_responsavel, index) in fastFiltro.responsavel.lista" :key="index" :value="nome_responsavel" >
                                    {{nome_responsavel}}
                                  </option>
                                </select>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                <label>Status:</label>
                                <select :class="fastFiltro.status.escolhido ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.status.escolhido" @change="aplicaFiltro()">
                                  <option value="">Todos</option>
                                  <option value="P" >
                                    Pago
                                  </option>
                                  <option value="A" >
                                    Pendente
                                  </option>
                                  <option value="V" >
                                    Vencido
                                  </option>
                                  <option value="C" >
                                    Cancelado
                                  </option>
                                </select>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                <label>Dia do vencimento:</label>
                                <select :class="fastFiltro.diaVencimento.escolhido ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.diaVencimento.escolhido" @change="aplicaFiltro()">
                                  <option value="">Todos</option>
                                  <option v-for="(dia, index) in fastFiltro.diaVencimento.lista" :key="index" :value="dia" >
                                    {{dia}}
                                  </option>
                                </select>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                                <label>Dia do registro:</label>
                                <select :class="fastFiltro.diaRegistro.escolhido ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.diaRegistro.escolhido" @change="aplicaFiltro()">
                                  <option value="">Todos</option>
                                  <option v-for="(dia, index) in fastFiltro.diaRegistro.lista" :key="index" :value="dia" >
                                    {{dia}}
                                  </option>
                                </select>
                              </div>

                            </div>
                          </div>
                          </transition>
                          
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 text-right mt-4">
                    
                  </div>
                  <div class="col-12 text-center mt-2">
                    <h4 v-if="!fastInvoice.loading">{{fastInvoice.filtro.length}} registros encontrados</h4>
                  </div>
                  <div class="col-12 table-responsive mt-2">
                    <table class="table table-sm">
                      <thead class="thead-dark">
                        <tr class="text-center">
                          <th>
                            <small class="font-weight-bold">Plataforma</small>
                          </th>
                          <th>
                            <small class="font-weight-bold">Aluno</small>
                          </th>
                          <th>
                            <small class="font-weight-bold">Valor</small>
                          </th>  
                          <th>
                            <small class="font-weight-bold">Registro</small>
                          </th>                   
                          <th>
                            <small class="font-weight-bold">Vencimento</small>
                          </th>
                          <th>
                            <small class="font-weight-bold">Status</small>
                          </th>
                          <th>
                            <small class="font-weight-bold">Tipo</small>
                          </th>
                          <th>
                            <small class="font-weight-bold">Parcela</small>
                          </th>
                          <th>
                            <small class="font-weight-bold">Ações</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastInvoice.loading">
                        <tr>
                          <td colspan="9" class="text-center">
                            <b-icon
                          icon="gear-fill"
                          animation="spin"
                        /> Carregando boletos
                          </td>
                        </tr>
                      </tbody> 
                      <tbody v-else-if="fastInvoice.paginacao.length">
                        <tr v-for="(e, index) in fastInvoice.paginacao" :key="index">
                          <td>
                            <small>
                              <a :href="'/plataforma/' + e.id_plataforma + '/tesouraria/emissao-boletos'">
                                {{e.nome_plataforma}}
                              </a>                          
                            </small> 
                          </td>
                          <td class="text-center">
                            <small>
                              <span>{{e.nome_aluno}}</span>
                            </small>
                          </td>
                          <td class="text-center">
                            <span v-if="e.valor_parcela_total"><small>R$ {{formataPreco(e.valor_parcela_total)}}</small> </span> 
                          </td>
                          <td class="text-center">
                            <span v-if="e.data_registro"><small>{{formataDataT(e.data_registro)}}</small> </span> 
                          </td>
                          <td class="text-center">
                            <span v-if="e.data_vencimento"><small>{{formataDataT(e.data_vencimento)}}</small> </span> 
                          </td>
                          <td class="text-center">
                            <span v-if="e.cancelado_parcela" class="text-secondary"><small>Cancelado</small> </span>
                            <span v-else-if="e.pago" class="text-success"><small>Pago</small> </span>
                            <span v-else-if="verificaVencimento(e.data_vencimento)">                          
                              <small class="text-danger">Vencido</small>                           
                            </span>
                            <span v-else class="text-warning"><small>Pendente</small> </span>
                          </td>
                          <td class="text-center">
                            <div v-if="e.tipo">
                              <span v-if="e.tipo == 'M'"><small>Taxa de matrícula</small> </span>
                              <span v-else-if="e.tipo == 'C'"><small>Curso</small> </span>
                              <span v-else-if="e.tipo == 'R'"><small>Requerimento</small> </span>
                              <span v-else-if="e.tipo == 'I'"><small>Item de cobrança</small> </span>
                              <span v-else-if="e.tipo == 'L'"><small>Curso livre</small> </span>
                            </div>
                            <span v-else><small>Curso</small> </span>
                          </td>
                          <td class="text-center">
                            <small>{{e.parcela}}/{{e.nr_parcelas}}</small>
                          </td>
                          <td class="text-center">
                            <button class="btn btn-sm btn-primary pb-0 pt-0" @click="exibeModalEditarBoleto(e)">
                              <small>Editar</small>
                            </button>
                          </td>
                        </tr>
                      </tbody> 
                      <tbody v-else>
                        <tr>
                          <td colspan="9" class="text-center">
                            Nenhum boleto encontrado
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-center mt-4">
                    <Pagination
                      :page-size="30"
                      :items="fastInvoice.filtro"
                      @changePage="fastInvoice.paginacao = $event"
                    />
                  </div> 
                </div>
              </div>
              </transition>

              <transition name="slide-fade">
              <div v-if="fastNavegacao.emitirBoleto" class="col-12">                
                  <div class="row">
                    <div class="col-12 mt-2">

                      <form-wizard
                        ref="wizard"
                        :start-index="fastSteps.currentStep"
                        :finish-button-text="'Confirmar geração de fatura'"
                        :back-button-text="'Voltar'"
                        :next-button-text="'Avançar'"
                        :title="'Emitir fatura'"
                        :subtitle="retornaNomePessoas()"
                        :step-size="'md'"
                        :validate-on-back="false"
                        shape="circle"
                        color="#5da3cc"
                        error-color="#e74c3c"
                        @on-complete="onComplete"
                        @on-validate="handleValidation"
                        @on-error="handleErrorMessage"
                      >
                        <wizard-step 
                          slot="step"
                          slot-scope="props"
                          :tab="props.tab"
                          :transition="props.transition"
                          :index="props.index"
                        />

                        <tab-content
                          title="Pessoa"
                          :before-change="validaPrimeiroPasso"
                          icon
                        >
                        <div class="card shadow mb-4">
                          <div class="card-header py-3">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <h6 class="m-0 font-weight-bold text-primary">
                                  1 - Pessoas da plataforma
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row mt-2 mb-2">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <h4 class="text-secondary">
                                  Adicione uma pessoa
                                </h4>     
                              </div>
                            </div>
                            <div class="row">                              

                              <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  placeholder="Filtrar por nome ou email"
                                  @keyup="filtraAlunos"
                                >
                              </div>

                              <div class="col-sm-12 col-md-12 col-lg-8 mb-1">
                                <span v-for="e in fastSteps.step1.pessoas" :key="e.id_pessoa" class="badge b-form-tag d-inline-flex align-items-baseline mw-100 mr-1 badge-secondary p-2">
                                  <span class="b-form-tag-content flex-grow-1 text-truncate">
                                    {{ e.first_name + " " + e.last_name }}
                                  </span>
                                  <button type="button" class="close b-form-tag-remove" @click.prevent="removePessoa(e)">×</button>
                                </span>
                              </div>
                              
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr>
                                      <th><small class="font-weight-bold">Nome</small></th>
                                      <th><small class="font-weight-bold">Email</small></th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Aceito</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ativo</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Adicionado em</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastPessoasLoading">
                                    <tr>
                                      <td
                                        colspan="6"
                                        class="text-center"
                                      >
                                        <b-icon
                                          icon="gear-fill"
                                          animation="spin"
                                        /> Carregando 
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastPessoasPaginacao.length">
                                    <tr
                                      v-for="(e, index) in fastPessoasPaginacao"
                                      :key="index"
                                    >
                                      <td class="align-middle">
                                        <small>{{ e.first_name + " " + e.last_name }}</small>                                  
                                      </td>
                                      <td class="align-middle">
                                        <small>{{ e.email }}</small>                                  
                                      </td>
                                      <td class="align-middle text-center">
                                        <small
                                          v-if="e.ativo == 'S'"
                                          class="badge badge-success mr-2"
                                        >sim</small>
                                        <small
                                          v-else
                                          class="badge badge-danger mr-2"
                                        >não</small>                                  
                                      </td>
                                      <td class="align-middle text-center">
                                        <small
                                          v-if="e.ativo == 'S'"
                                          class="badge badge-success mr-2"
                                        >sim</small>
                                        <small
                                          v-else
                                          class="badge badge-danger mr-2"
                                        >Não</small>                               
                                      </td>
                                      <td class="align-middle text-center">
                                        <small v-if="e.data_convite_envio">{{ new Date(e.data_convite_envio).toLocaleDateString() }}</small>  
                                        <small v-else>Sem registro</small>                              
                                      </td>                           
                                      <td class="text-center align-middle">  
                                        <div v-if="e.id_pessoa">
                                          <a           
                                            v-if="!verificaPessoaAdicionada(e)"                                 
                                            @click="adicionaPessoa(e)"
                                            class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1"
                                          >
                                            <small class="text-white">+ Adicionar</small>
                                          </a>  
                                         
                                        </div>  
                                        <span v-else class="text-danger">
                                          <small>Pessoa não identificada</small>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="6"
                                        class="text-center"
                                      >
                                        Nenhum aluno cadastrado na plataforma
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="col-12 text-center mt-4">
                                <Pagination
                                  :page-size="20"
                                  :items="fastPessoasFiltro"
                                  @changePage="fastPessoasPaginacao = $event"
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                        </tab-content>
                        <tab-content
                          title="Itens"
                          :before-change="validaSegundoPasso"
                          icon
                        >
                        <div class="card shadow mb-4">
                          <div class="card-header py-3">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <h6 class="m-0 font-weight-bold text-primary">
                                  2 - Itens de cobrança
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row mt-2 mb-2">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <h4 class="text-secondary">
                                  Adicione itens
                                </h4>     
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                                <button class="btn btn-sm btn-primary" @click="showModal('modalListaItensCobranca')">
                                  <small>+ Novo item de cobrança</small>                                      
                                </button>
                              </div>
                            </div>
                            <div class="row">  

                              <div class="col-12">
                                <ul class="list-group">
                                  <li class="list-group-item" v-for="(i, index) in fastSteps.step2.itens" :key="index">
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-6">
                                        <label>Nome do item</label>
                                        <input :value="i.nome_item" class="form-control" readonly />
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-2">
                                        <label>Valor</label>
                                        <money class="form-control" v-model="i.valor_item" @keyup.native="alteraValorTotalItens()"/>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-2">
                                        <label>Quantidade</label>
                                        <input v-model="i.quantidade" type="number" class="form-control" @change="alteraValorTotalItens()"/>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-2 d-flex justify-content-center align-items-end">
                                        <button class="btn btn-sm btn-danger" @click="removeItemCobranca(i)">
                                          <small>- Remover</small>
                                        </button>
                                      </div>
                                    </div>                                    
                                  </li>                                  
                                </ul>
                                <div v-if="!fastSteps.step2.itens.length" class="text-center">
                                  <h6 class="text-secondary">Nenhum item adicionado</h6>
                                </div>
                              </div>

                              <div class="col-12 mt-4">
                                <h4 v-if="fastSteps.step2.itens.length" class="text-secondary">
                                  Valor total: R$ {{formataPreco(fastSteps.step2.total)}}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        </tab-content>
                        <tab-content
                          title="Pagamento"
                          :before-change="validaTerceiroPasso"
                          icon
                        >
                        <div class="card shadow mb-4">
                          <div class="card-header py-3">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <h6 class="m-0 font-weight-bold text-primary">
                                  3 - Opções de pagamento
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row mt-2 mb-2">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <h4 class="text-secondary">
                                  Plano de pagamento
                                </h4>  
                                <select
                                  v-if="fastSteps.step3.plano.loading"
                                  class="form-control"
                                >
                                  <option value="">
                                    Carregando planos...
                                  </option>
                                </select>                        
                                <select
                                  v-else-if="fastSecretariaPlanoPagamento.length"
                                  v-model="fastSteps.step3.plano.id_plano_pagamento"
                                  class="form-control"
                                  @change="verificaConfiguracaoPagamento()"                                  
                                >
                                  <option value="0">
                                    -- Selecione um plano de pagamento --
                                  </option>
                                  <option
                                    v-for="plano in fastSecretariaPlanoPagamento"
                                    :key="plano.id_plano_pagamento"
                                    :value="plano.id_plano_pagamento"
                                  >
                                    {{ plano.nome_plano }}
                                  </option>
                                </select>  
                                <select
                                  v-else
                                  class="form-control"
                                >
                                  <option value="">
                                    -- Nenhum plano de pagamento encontrado --
                                  </option>
                                </select>    
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <h4 class="text-secondary">
                                  Tipo de pagamento
                                </h4>  
                                <select
                                  v-if="fastSteps.step3.tipo.loading"
                                  class="form-control"
                                >
                                  <option value="">
                                    Carregando tipos...
                                  </option>
                                </select>                        
                                <select
                                  v-else-if="fastSecretariaTipoPagamento.length"
                                  v-model="fastSteps.step3.tipo.id_tipo_pagamento"
                                  class="form-control"
                                  @change="verificaConfiguracaoPagamento()"
                                >
                                  <option value="0">
                                    -- Selecione um tipo de pagamento --
                                  </option>
                                  <option
                                    v-for="tipo in fastSecretariaTipoPagamento"
                                    :key="tipo.id_tipo_pagamento"
                                    :value="tipo.id_tipo_pagamento"
                                  >
                                    {{ tipo.nome_tipo_pagamento }}
                                    <span v-if="tipo.forma_pagamento"> - {{ formataFormaPagamento(tipo.forma_pagamento) }}</span>
                                  </option>
                                </select>  
                                <select
                                  v-else
                                  class="form-control"
                                >
                                  <option value="">
                                    -- Nenhum tipo de pagamento encontrado --
                                  </option>
                                </select>    
                              </div>
                            </div>
                            <div
                              v-if="fastSteps.step3.plano.id_plano_pagamento && fastSteps.step3.tipo.id_tipo_pagamento"
                              class="row mt-4 mb-2"
                            >
                              <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="row mt-2">
                                  <div class="col-sm-12 col-md-12 col-lg-6">
                                    <div class="row mb-4">
                                      <div class="col-sm-12 col-md-12 col-lg-12">
                                        <h5>Detalhes do plano</h5>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                                        <p class="mb-0">
                                          <span>Nome do plano:</span> {{ fastSteps.step3.plano.nome_plano }}
                                        </p>
                                        <p class="mb-0">
                                          <span>Observações:</span> {{ fastSteps.step3.plano.obs }}
                                        </p>
                                        <p class="mb-0">
                                          <span>Dia/mês: </span> 
                                          <span v-if="fastSteps.step3.plano.dia_mes == 'D'"> Dia </span>
                                          <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'"> Mês </span>
                                        </p>
                                        <p class="mb-0">
                                          <span>Periodicidade: </span> 
                                          <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 0">Indeterminado</span>
                                          <span v-else-if="fastSteps.step3.plano.dia_mes == 'D'">
                                            <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 1">Todo dia</span>
                                            <span v-else>A cada {{ fastSteps.step3.plano.periocidade_cada_unidade }} dias</span>
                                          </span>
                                          <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'">                                          
                                            <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 1">Todo mês</span>
                                            <span v-else>A cada {{ fastSteps.step3.plano.periocidade_cada_unidade }} meses</span>
                                          </span>
                                        </p>
                                        <p class="mb-0">
                                          <span>Tempo determinado:</span> 
                                          <span v-if="fastSteps.step3.plano.tempo_determinado == 0">Indeterminado</span>
                                          <span v-else-if="fastSteps.step3.plano.dia_mes == 'D'">
                                            {{ fastSteps.step3.plano.tempo_determinado }} dias
                                          </span>
                                          <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'">                                          
                                            {{ fastSteps.step3.plano.tempo_determinado }} meses
                                          </span>
                                        </p>
                                      </div>                                    
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-6">
                                    <div class="row mb-4">
                                      <div class="col-sm-12 col-md-12 col-lg-12">
                                        <h5>Descontos e acréscimos do plano</h5>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-12 mt-2">                                      
                                        <div class="row text-center">
                                          <div class="col-sm-12 col-md-12 col-lg-6">
                                            <small>Acréscimo (total)</small>
                                            <money
                                              v-model="fastSteps.step3.invoice.acrescimo"                                              
                                              class="form-control"
                                              @change.native="aplicaAjusteValor()"
                                            />
                                          </div>   
                                          <div class="col-sm-12 col-md-12 col-lg-6">
                                            <small>Desconto (total)</small>
                                            <money
                                              v-model="fastSteps.step3.invoice.desconto_total"
                                              class="form-control"
                                              @change.native="aplicaAjusteValor()"
                                            />
                                          </div>    
                                          <div class="col-sm-12 col-md-12 col-lg-6">
                                            <small>Taxa do boleto (parcela)</small>
                                            <money
                                              v-model="fastSteps.step3.invoice.taxa_boleto"
                                              class="form-control"
                                              @change.native="aplicaAjusteValor()"
                                            />
                                          </div>   
                                          <div class="col-sm-12 col-md-12 col-lg-6">
                                            <small>Taxa do cartão (parcela)</small>
                                            <money
                                              v-model="fastSteps.step3.invoice.taxa_cartao"
                                              class="form-control"
                                              @change.native="aplicaAjusteValor()"
                                            />
                                          </div>  
                                          <div class="col-sm-12 col-md-12 col-lg-6">
                                            <small>Outras taxas (parcela)</small>
                                            <money
                                              v-model="fastSteps.step3.invoice.outras_taxas"
                                              class="form-control"
                                              @change.native="aplicaAjusteValor()"
                                            />
                                          </div>       
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="row mb-4">
                                      <div class="col-sm-12 col-md-12 col-lg-12">
                                        <h5>Valores do plano</h5>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-12 mt-2">                                      
                                        <p class="mb-0">
                                          <span>Subtotal: R$ {{ formataPreco(fastSteps.step3.invoice.subtotal) }}</span>
                                          <span
                                            v-if="fastSteps.step3.invoice.acrescimo > 0"
                                            class="text-danger"
                                          > + R$ {{ formataPreco(fastSteps.step3.invoice.acrescimo) }} (acréscimo)</span>
                                          <span
                                            v-if="fastSteps.step3.invoice.desconto_total > 0"
                                            class="text-success"
                                          > - R$ {{ formataPreco(fastSteps.step3.invoice.desconto_total) }} (desconto)</span>
                                          <span v-if="fastSteps.step3.plano.parcelas.length">
                                            <span v-if="fastSteps.step3.plano.parcelas[0].taxa_boleto || fastSteps.step3.plano.parcelas[0].taxa_cartao || fastSteps.step3.plano.parcelas[0].outras_taxas" class="text-danger"> + taxas das parcelas</span>
                                          </span>
                                        </p>
                                        <p>
                                          <span>Total: R$ {{ formataPreco(fastSteps.step3.invoice.total) }}</span>
                                        </p>
                                        <p
                                          v-if="fastSteps.step3.plano.parcelas.length"
                                          class="mb-0 d-none"
                                        >
                                          {{ fastSteps.step3.plano.parcelas.length }} x R$ {{ formataPreco(fastSteps.step3.plano.parcelas[0].valor_parcela) }}
                                          <span v-if="fastSteps.step3.plano.tempo_determinado == 0"> por tempo indeterminado</span>
                                        </p>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                        <button
                                          class="btn btn-sm btn-primary"
                                          @click="showModal('modalDefinirPrimeiraParcela')"
                                        >
                                          <small>Definir primeira parcela</small>
                                        </button>                                      
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div
                                          v-if="fastSteps.step3.plano.parcelas.length"
                                          class="mt-2 list-group"
                                        >
                                          <div
                                            v-for="(parcela, index) in fastSteps.step3.plano.parcelas"
                                            :key="index"
                                            class="mb-2 text-secondary list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                          >
                                            <b class="text-info">Parcela {{ index + 1 }} - {{ parcela.data_formatada }} - R$ {{ formataPreco(parcela.valor_parcela) }}
                                              <span
                                                v-if="fastSteps.step3.invoice.taxa_boleto > 0"
                                                class="text-danger"
                                              > + R$ {{ formataPreco(fastSteps.step3.invoice.taxa_boleto) }} (taxa boleto)</span>
                                              <span
                                                v-if="fastSteps.step3.invoice.taxa_cartao > 0"
                                                class="text-danger"
                                              > + R$ {{ formataPreco(fastSteps.step3.invoice.taxa_cartao) }} (taxa cartão)</span>
                                              <span
                                                v-if="fastSteps.step3.invoice.outras_taxas > 0"
                                                class="text-danger"
                                              > + R$ {{ formataPreco(fastSteps.step3.invoice.outras_taxas) }} (outras taxas)</span>
                                              <span v-if="fastSteps.step3.invoice.taxa_boleto > 0 || fastSteps.step3.invoice.taxa_cartao > 0 || fastSteps.step3.invoice.outras_taxas > 0"> = R$ {{ formataPreco(parseFloat(parcela.valor_parcela) + parseFloat(fastSteps.step3.invoice.taxa_boleto ? fastSteps.step3.invoice.taxa_boleto : 0) + parseFloat(fastSteps.step3.invoice.taxa_cartao ? fastSteps.step3.invoice.taxa_cartao : 0) + parseFloat(fastSteps.step3.invoice.outras_taxas ? fastSteps.step3.invoice.outras_taxas : 0)) }}</span>
                                            </b>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <label>Observações</label>
                                    <textarea class="form-control" v-model="fastSteps.step3.invoice.obs" maxlength="100"></textarea>
                                  </div>
                                </div>
                                <div class="row mt-4 d-none">
                                  <div class="col-sm-12 col-md-12 col-lg-12">
                                    <h4 class="text-secondary">
                                      Schema de comissão <span class="badge badge-danger">não ativado para geração de itens de cobrança</span> 
                                    </h4>
                                  </div>
                                </div>
                                <div class="row mt-4 d-none">
                                  <div v-if="comissaoHabilitada()" class="col-sm-12 col-md-12 col-lg-12">                                  
                                    <div class="row mb-4">
                                      <div class="col-sm-12 col-md-12 col-lg-6">
                                        <h4 class="text-secondary">
                                          Schema de comissão
                                        </h4>                          
                                        <select
                                          v-if="fastComissaoSchema.total.length"
                                          class="form-control"
                                          @change="getComissaoSchemaDeterminacoes"
                                        >
                                          <option
                                            value="0"
                                            selected
                                          >
                                            -- Nenhum schema de comissão selecionado --
                                          </option>
                                          <option
                                            v-for="c in fastComissaoSchema.total"
                                            :key="c.id_comissao_schema"
                                            :value="c.id_comissao_schema"
                                          >
                                            {{ c.nome_schema_comissao }}
                                          </option>
                                        </select>  
                                        <select
                                          v-else
                                          class="form-control"
                                        >
                                          <option value="">
                                            -- Nenhum schema de comissão cadastrado --
                                          </option>
                                        </select>    
                                      </div>
                                      <div v-if="!fastComissaoSchemaDeterminacoes.loading" class="col-sm-12 col-md-12 col-lg-12">                                         
                                        <div v-if="fastComissaoSchemaDeterminacoes.loading" class="row">
                                          <div class="col-12 mt-4 text-center">
                                            <span>Carregando itens...</span>
                                          </div>
                                        </div>
                                        <div v-else-if="fastComissaoSchemaDeterminacoes.total.length" class="row">
                                          <div class="col-12 table-responsive mt-4">
                                            <table class="table table-sm table-bordered">
                                              <thead class="thead-dark">
                                                <tr class="text-center">
                                                  <th>
                                                    <small
                                                      class="font-weight-bold"
                                                    >Tipo determinação</small>
                                                  </th>
                                                  <th>
                                                    <small
                                                      class="font-weight-bold"
                                                    >Curso</small>
                                                  </th>
                                                  <th>
                                                    <small
                                                      class="font-weight-bold"
                                                    >Comissão</small>
                                                  </th>
                                                  <th>
                                                    <small
                                                      class="font-weight-bold"
                                                    >Comissão parcela</small>
                                                  </th>
                                                  <th>
                                                    <small
                                                      class="font-weight-bold"
                                                    >Comissão total</small>
                                                  </th>
                                                  <th>
                                                    <small
                                                      class="font-weight-bold"
                                                    ></small>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr v-for="(e, index) in fastComissaoSchemaDeterminacoes.total" :key="index">
                                                  <td v-if="e.id_pessoa" class="align-middle text-center">
                                                    Pessoa específica
                                                  </td>
                                                  <td v-else-if="e.id_funcao_pessoa" class="align-middle text-center">
                                                    {{e.nome_funcao_pessoa}}
                                                  </td>
                                                  <td v-else class="align-middle text-center text-danger">
                                                    Não configurado
                                                  </td>

                                                  <td v-if="e.id_curso" class="align-middle text-center">
                                                    {{e.nome_curso ? e.nome_curso : e.nome_curso_mae }}
                                                  </td>
                                                  <td v-else-if="e.id_secretaria_curso" class="align-middle text-center">
                                                    {{e.nome_curso_secretaria}}
                                                  </td>
                                                  <td v-else-if="e.id_secretaria_curso_programa" class="align-middle text-center">
                                                    {{e.sigla_programa}}
                                                  </td>
                                                  <td v-else-if="e.id_secretaria_curso_programa_unidade" class="align-middle text-center">
                                                    {{e.nome_curso_unidade}}
                                                  </td>
                                                  <td v-else class="align-middle text-center">
                                                    Todos
                                                  </td>

                                                  <td v-if="e.percentual && e.percentual > 0" class="align-middle text-center">
                                                    {{e.percentual}}%
                                                  </td>
                                                  <td v-else-if="e.valor && e.valor > 0" class="align-middle text-center">
                                                    R$ {{formataPreco(e.valor)}}
                                                  </td>
                                                  <td v-else class="align-middle text-center text-danger">
                                                    Não configurado
                                                  </td>

                                                  <td class="align-middle text-center">
                                                    <span v-if="e.valor_comissao_parcela">
                                                      R$ {{formataPreco(e.valor_comissao_parcela)}}
                                                    </span>
                                                  </td>

                                                  <td class="align-middle text-center">
                                                    <span v-if="e.valor_comissao_total">
                                                      R$ {{formataPreco(e.valor_comissao_total)}}
                                                    </span>
                                                  </td>

                                                  <td v-if="e.id_pessoa" class="align-middle text-center">
                                                    {{e.nome_razao + ' ' + e.sobrenome_fantasia + ' (' + e.email + ')'}}
                                                  </td>
                                                  <td v-else-if="e.id_funcao_pessoa" class="align-middle text-center">                                                  
                                                    <select v-if="e.pessoas.length" class="form-control">
                                                      <option value="" class="text-center">
                                                        -- Selecione uma pessoa com a função {{e.nome_funcao_pessoa}} --
                                                      </option>
                                                      <option v-for="p in e.pessoas" :key="p.id_pessoa" :value="p.id_pessoa">
                                                        {{p.nome_razao + ' ' + p.sobrenome_fantasia + ' (' + p.email + ')'}}
                                                      </option>
                                                    </select>
                                                    <span v-else class="text-danger">
                                                      Nenhuma pessoa cadastrada com essa função
                                                    </span>
                                                  </td>

                                                </tr>
                                              </tbody>
                                            </table>

                                          </div>
                                        </div>
                                        <div v-else class="col-12 mt-4 text-center">
                                          <span>Nenhum item adicionado</span>
                                        </div>  
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else class="col-sm-12 col-md-12 col-lg-12">
                                    <h4 class="text-secondary">
                                      Schema de comissão <span class="badge badge-danger">Não aplicável a este item</span> 
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </tab-content>                       
                      </form-wizard>

                    </div>
                  </div>
              </div>
              </transition>

              <transition name="slide-fade">
              <div v-if="fastNavegacao.editarBoleto" class="col-12">                
                <div class="card shadow mb-4">
                  <div class="card-header py-3">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Editar fatura
                        </h6>
                      </div>  
                      <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                        <div v-if="fastErrosGerencianetLoading" class="text-warning d-flex align-items-center justify-content-end">                          
                            <b-icon icon="gear-fill" animation="spin" /> <span class="ml-2"> Verificando inconsistências</span>
                        </div>
                        <div v-else-if="fastErrosGerencianet.length">
                          <a href="#" class="text-danger d-flex align-items-center justify-content-end" @click.prevent="showModal('modalErrosFaturaGateway')">
                            <b-icon icon="exclamation-circle-fill" variant="danger" font-scale="2" ></b-icon> <span class="ml-2">Atenção</span> 
                          </a> 
                        </div>
                        <div v-else class="text-success d-flex align-items-center justify-content-end">
                          <b-icon icon="check2-circle" /> <span class="ml-2"> Nenhuma inconsistência detectada</span>
                        </div>
                                               
                      </div>                                           
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col-12 mt-2">

                      <div class="row mt-4">
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-2 mb-4">
                          <h4 class="m-0 text-info">
                              Invoice #{{fastTesourariaInvoice.id_invoice}} - Parcela {{fastTesourariaInvoice.parcela}}/{{fastTesourariaInvoice.nr_parcelas}} 
                            </h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-2 mb-4 text-right">

                          <div v-if="fastTesourariaInvoice.cancelado_parcela">
                            <h4 class="text-danger">Parcela cancelada</h4>

                          </div>
                          <div v-else-if="fastTesourariaInvoice.pago">
                            <h4 class="text-success">Parcela paga</h4>   
                          </div>
                          <div v-else-if="verificaVencimento(fastTesourariaInvoice.data_vencimento)">
                            <h4 class="text-danger">Parcela vencida</h4>   
                            <a href="#" class="btn btn-sm btn-danger" @click.prevent="showModal('modalCancelarParcela')">
                              <b-icon icon="x-octagon"></b-icon> <span>Cancelar parcela</span>
                            </a>
                          </div>
                          <div v-else>
                            <h4 class="text-warning">Pagamento pendente</h4>  
                            <a href="#" class="btn btn-sm btn-danger" @click.prevent="showModal('modalCancelarParcela')">
                              <b-icon icon="x-octagon"></b-icon> <span>Cancelar parcela</span>
                            </a>                          
                          </div>

                        </div>
                        <div class="col-12">
                          <div class="row mb-4">
                            <div class="col-12 table-responsive">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr>
                                    <th class="text-center">
                                      Parcela
                                    </th>
                                    <th class="text-center">
                                      Valor
                                    </th>
                                    <th class="text-center">
                                      Pago
                                    </th>
                                    <th class="text-center">
                                      Vencimento
                                    </th>
                                    <th class="text-center">
                                      Status
                                    </th>
                                    <th class="text-center">
                                      Ações
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastTesourariaInvoice.parcelasLoading">
                                  <tr>
                                    <td
                                      colspan="6"
                                      class="text-center"
                                    >
                                      <span> <b-icon icon="gear-fill" animation="spin"/> Carregando parcelas</span>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastTesourariaInvoice.parcelas.length">
                                  <tr
                                    v-for="(
                                      parcela, index
                                    ) in fastTesourariaInvoice.parcelas"
                                    :key="index"
                                  >
                                    <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                                      {{ parcela.parcela }}
                                    </td>
                                    <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                                      R$ {{ formataPreco(parcela.valor_parcela_total) }}
                                      <span class="text-danger">{{ retornaParcelaComissao(parcela) }}</span> 
                                    </td>
                                    <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                                      <button
                                        v-if="parcela.pago"
                                        class="btn btn-sm btn-success pt-0 pb-0"
                                      >
                                        <small>Sim</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                      >
                                        <small>Não</small>
                                      </button>
                                    </td>
                                    <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                                      {{ formataDataT(parcela.data_vencimento) }}
                                    </td>
                                    <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                                      <span v-if="!parcela.path_boleto" class="text-secondary"><small>Boleto não gerado</small> </span>
                                      <span v-else-if="parcela.cancelado_parcela" class="text-secondary"><small>Cancelado</small> </span>
                                      <span v-else-if="parcela.pago" class="text-success"><small>Pago</small> </span>
                                      <span v-else-if="verificaVencimento(parcela.data_vencimento)">                          
                                        <small class="text-danger">Vencido</small>                           
                                      </span>
                                      <span v-else class="text-warning"><small>Pendente</small> </span>
                                    </td>
                                    <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                                      <button v-if="fastTesourariaInvoice.parcela != parcela.parcela" class="btn btn-sm btn-primary pt-0 pb-0 mr-2" @click="exibeModalEditarOutroBoleto(parcela)">
                                        <small class="text-white">Editar</small>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                      colspan="6"
                                      class="text-center"
                                    >
                                      <span>Nenhuma parcela gerada</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                          <label>Valor da parcela</label>
                          <money :value="fastTesourariaInvoice.valor_parcela" :class="!fastTesourariaInvoice.valor_parcela ? 'form-control border border-danger' : 'form-control'" readonly></money>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                          <label>Acréscimo</label>
                          <money :value="fastTesourariaInvoice.acrescimo" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>                         
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                          <label>Taxa do boleto</label>
                          <money :value="fastTesourariaInvoice.taxa_boleto" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                          <label>Taxa do cartão</label>
                          <money :value="fastTesourariaInvoice.taxa_cartao" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                          <label>Outras taxas</label>
                          <money :value="fastTesourariaInvoice.outras_taxas" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                          <label>Desconto</label>
                          <money :value="fastTesourariaInvoice.descontos" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                          <label>Data de vencimento</label>
                          <input
                            v-if="!fastTesourariaInvoice.pago && !fastTesourariaInvoice.cancelado_parcela"
                            v-model="fastTesourariaInvoice.data_vencimento"
                            @change="fastTesourariaInvoice.alteracoes_pendentes_vencimento = true"
                            type="date"
                            class="form-control"
                            maxlength="30"
                          >
                          <input
                            v-else
                            :value="fastTesourariaInvoice.data_vencimento"
                            type="date"
                            class="form-control"
                            readonly
                          >
                          <div v-if="fastTesourariaInvoice.alteracoes_pendentes_vencimento" class="col-12 mt-2 text-center">
                            <button class="btn btn-sm btn-primary blink_me" @click="editarInvoiceParcela('data_vencimento')">
                              <small>Confirmar alteração</small>
                            </button>
                          </div>
                        </div>
                        <div v-if="!fastTesourariaInvoice.cancelado_parcela" class="col-sm-12 col-md-12 col-lg-2 text-center">
                          <label>Pago</label>
                          <div v-if="fastPagtoIdentifiedGerencianet">
                            <small class="text-primary mb-sm-2">Processando Pagamento</small>
                          </div>
                          <div v-else>
                            <div v-if="!fastTesourariaInvoice.alteracoes_pendentes_pago" class="fd-app-meus-cursos mt-0 ml-1">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                >
                                  <label v-if="!fastTesourariaInvoice.pago" class="switch">
                                    <input                                    
                                      v-model="fastTesourariaInvoice.pago"
                                      @change="exibeModalConfirmarBaixaManual()"
                                      checked="checked"
                                      type="checkbox"
                                    >
                                    <span class="slider round" />
                                  </label>
                                  <span v-else class="font-weight-bold text-success">
                                    <b-icon icon="check-circle" aria-hidden="true" font-scale="2"></b-icon> 
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div v-else class="mt-2 text-center">
                              <button class="btn btn-sm btn-primary blink_me" @click="editarInvoiceParcela('baixa_manual')">
                                <small>Confirmar baixa</small>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div v-if="fastTesourariaInvoice.log_pagto_auditoria" class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <label class="font-weight-bold text-secondary">Log de auditoria: </label>&nbsp; <span class="text-danger">{{fastTesourariaInvoice.log_pagto_auditoria}}</span>
                        </div>
                        <div v-if="fastTesourariaInvoice.data_cancelamento_parcela && fastTesourariaInvoice.id_usuario_cancelamento_parcela" class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <label class="font-weight-bold text-secondary">Log de auditoria: </label>&nbsp; <span class="text-danger">O usuário #{{fastTesourariaInvoice.id_usuario_cancelamento_parcela}} cancelou na data {{formataDataHoraT(fastTesourariaInvoice.data_cancelamento_parcela)}}</span>
                        </div>
                        <div v-if="fastTesourariaInvoice.motivo_cancelamento_parcela" class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <label class="font-weight-bold text-secondary">Motivo do cancelamento: </label>&nbsp; <span class="text-danger">{{fastTesourariaInvoice.motivo_cancelamento_parcela}}</span>
                        </div>
                        

                        <div v-if="!fastTesourariaInvoice.cancelado_parcela" class="col-sm-12 col-md-12 col-lg-12 mb-4">
                          <label class="font-weight-bold text-secondary">Comprovante:</label>&nbsp;
                          <a v-if="fastTesourariaInvoice.path_comprovante_pagamento" :href="ajustaLinkApiAntiga(fastTesourariaInvoice.path_comprovante_pagamento)" target="_blank" download class="btn btn-sm btn-success pb-0 pt-0">Download</a>

                          <uploader
                            :file-status-text="statusText"
                            :options="optionsArquivo"
                            class="uploader-example"
                            @file-success="fileSuccess"
                            @file-added="fileValidation(fastTesourariaInvoice.id_invoice, fastTesourariaInvoice.parcela)"
                          >
                            <uploader-unsupport />
                            <uploader-drop>
                              <p
                                v-if="fastTesourariaInvoice.path_comprovante_pagamento"
                                class="aluno_font_color"
                              >
                                Atualizar comprovante de pagamento
                              </p>
                              <p
                                v-else
                                class="aluno_font_color"
                              >
                                Inserir comprovante de pagamento
                              </p>
                              <uploader-btn
                                :attrs="restrictArquivo"
                              >
                                Clique aqui para selecionar
                              </uploader-btn>
                            </uploader-drop>
                            <uploader-list />
                          </uploader> 

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <label class="font-weight-bold text-secondary">Valor total da parcela:</label>&nbsp;
                          <span>R$ {{ retornaValorTotalParcela() }}</span>
                          <span class="text-danger"> {{ retornaParcelaComissao(fastTesourariaInvoice) }}</span> 
                        </div>

                        <div v-if="!fastTesourariaInvoice.cancelado_parcela" class="col-sm-12 col-md-12 col-lg-12 mb-4">
                          <b-input-group>
                            <template #prepend>
                              <b-input-group-text>Boleto</b-input-group-text>
                            </template>
                            <b-form-input v-if="!fastTesourariaInvoice.pago" v-model="fastTesourariaInvoice.path_boleto" readonly></b-form-input>
                            <b-form-input v-else :value="fastTesourariaInvoice.path_boleto" readonly></b-form-input>
                            <b-input-group-append v-if="fastTesourariaInvoice.path_boleto != 'Nenhum boleto registrado'">
                              <b-button v-if="!fastLinkClipboard" variant="success" @click="copyURLBoleto()">Copiar</b-button>
                              <b-button v-else variant="secondary" @click="copyURLBoleto()">Copiado!</b-button>
                            </b-input-group-append>
                          </b-input-group>

                          <div v-if="fastTesourariaInvoice.path_boleto == 'Nenhum boleto registrado' && fastTesourariaInvoiceComissao.total.length" class="mt-2">
                            <button v-if="fastTesourariaNovoBoleto.loading" class="btn btn-secondary">Gerando boleto...</button>
                            <button v-else class="btn btn-primary" @click="gerarBoletoComissaoManual()">Gerar boleto manualmente</button>
                          </div>
                        </div>


                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                              <label class="font-weight-bold text-secondary">Aluno:</label>&nbsp; <span>{{fastTesourariaInvoice.nome_aluno}}</span>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                              <label class="font-weight-bold text-secondary">CPF do aluno:</label>&nbsp; <span>{{fastTesourariaInvoice.cpf_aluno}}</span>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                              <label class="font-weight-bold text-secondary">Telefone do aluno:</label>&nbsp; <span>{{fastTesourariaInvoice.aluno.telefone}}</span>
                              <a v-if="fastTesourariaInvoice.aluno.telefone && fastTesourariaInvoice.path_boleto != 'Nenhum boleto registrado' && !fastTesourariaInvoice.cancelado_parcela" target="_blank" :href="retornaLinkWhats(fastTesourariaInvoice.aluno.telefone, fastTesourariaInvoice.path_boleto, fastTesourariaInvoice.parcela, fastTesourariaInvoice.valor_parcela_total, fastTesourariaInvoice.data_vencimento, fastTesourariaInvoice.nome_plataforma, fastTesourariaInvoice.tipo)" class="btn btn-sm btn-success pb-0 pt-0 ml-2">
                                <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                              <label class="font-weight-bold text-secondary">Responsável:</label>&nbsp; <span>{{fastTesourariaInvoice.nome_responsavel}}</span>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                              <label class="font-weight-bold text-secondary">CPF do responsável:</label>&nbsp; <span>{{fastTesourariaInvoice.cpf_responsavel}}</span>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                              <label class="font-weight-bold text-secondary">Telefone do responsável:</label>&nbsp; <span>{{fastTesourariaInvoice.responsavel.telefone}}</span>
                              <a v-if="fastTesourariaInvoice.responsavel.telefone && fastTesourariaInvoice.path_boleto != 'Nenhum boleto registrado' && !fastTesourariaInvoice.cancelado_parcela" target="_blank" :href="retornaLinkWhats(fastTesourariaInvoice.responsavel.telefone, fastTesourariaInvoice.path_boleto, fastTesourariaInvoice.parcela, fastTesourariaInvoice.valor_parcela_total, fastTesourariaInvoice.data_vencimento, fastTesourariaInvoice.nome_plataforma, fastTesourariaInvoice.tipo)" class="btn btn-sm btn-success pb-0 pt-0 ml-2">
                                <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                          <label class="font-weight-bold text-secondary">Origem:</label>&nbsp;
                          <label v-if="fastTesourariaInvoice.tipo">
                            <span v-if="fastTesourariaInvoice.tipo == 'M'">Taxa de matrícula</span>
                            <span v-else-if="fastTesourariaInvoice.tipo == 'C'">Curso </span>
                            <span v-else-if="fastTesourariaInvoice.tipo == 'R'">Requerimento </span>
                            <span v-else-if="fastTesourariaInvoice.tipo == 'I'">Item de cobrança </span>
                            <span v-else-if="fastTesourariaInvoice.tipo == 'L'">Curso livre </span>
                          </label>
                          <span v-else>Curso</span>
                          <span v-if="fastTesourariaInvoice.id_matricula"> - Matrícula #{{fastTesourariaInvoice.id_matricula}}</span>
                        </div>
                      </div>

                      <div v-if="fastTesourariaInvoice.tipo == 'I' || fastTesourariaInvoice.tipo == 'L'" class="row mt-4">
                        <div class="col-12 mb-2">
                          <h4 class="m-0 text-secondary">
                              Itens de cobrança
                            </h4>
                        </div>
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Item de cobrança</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Valor padrão</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Quantidade</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastTesourariaInvoice.itensLoading">
                              <tr>
                                <td
                                  colspan="3"
                                  class="text-center"
                                >
                                Carregando...
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastTesourariaInvoice.itensCobranca.length">
                              <tr
                                v-for="e in fastTesourariaInvoice.itensCobranca"
                                :key="e.id_item_cobranca"
                              >
                                <td class="align-middle text-center">
                                  {{ e.nome_item }}
                                </td>   
                                <td class="align-middle text-center">
                                  R$ {{ formataPreco(e.valor_item) }}
                                </td>                              
                                <td class="align-middle text-center">
                                  {{ e.quantidade }}
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="3"
                                  class="text-center"
                                >
                                  Nenhum item encontrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="row mt-4">    
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                          <h4 class="m-0 text-info">
                            Dados Invoice #{{fastTesourariaInvoice.id_invoice}}  
                          </h4>
                        </div>   

                        <div class="col-sm-12 col-md-12 col-lg-6 mb-4 text-right">
                          <div v-if="fastTesourariaInvoice.cancelado_invoice">
                            <h4 class="text-danger">Invoice cancelada</h4>  
                          </div>
                          <div v-else>
                            <h4 class="text-success">Invoice ativo</h4>   
                            <a href="#" class="btn btn-sm btn-danger" @click.prevent="showModal('modalCancelarInvoice')">
                              <b-icon icon="x-octagon"></b-icon> <span>Cancelar invoice</span>
                            </a>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <label class="font-weight-bold text-secondary">Valor total:</label>&nbsp;
                          <span>R$ {{ formataPreco(fastTesourariaInvoice.total) }}</span>
                          <span class="text-danger"> {{retornaTotalComissao()}}</span> 
                        </div>  
                        <div v-if="fastTesourariaInvoice.obs" class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <label class="font-weight-bold text-secondary">Observação:</label>&nbsp;
                          <span>{{ fastTesourariaInvoice.obs }}</span>
                        </div>  
                        <div class="col-sm-12 col-md-12 col-lg-2 text-center d-none">
                          <label>Cancelado</label>
                          <div class="fd-app-meus-cursos mt-0 ml-1">
                            <div class="fd-app-curso-aulas-list mt-0">
                              <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                <label class="switch">
                                  <input
                                    v-model="fastTesourariaInvoice.cancelado_invoice"
                                    checked="checked"
                                    type="checkbox"
                                  >
                                  <span class="slider round" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                </div>
              </div>   
              </transition>

            </div>
          </div>
          <!-- /Conteúdo principal -->
        </section>
        <div class="container">
          <div class="mt-2 mb-5">
            <a
              v-if="fastNavegacao.emitirBoleto || fastNavegacao.editarBoleto"
              class="btn btn-red-hollow text-danger"
              @click.prevent="alteraFastNavegacao('exibirBoletos')"
            >
              <b-icon-arrow-return-left />
              Exibir faturas 
            </a>
            <a
              v-else
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' +
                  $route.params.id_plataforma +
                  '/tesouraria'
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar para menu
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalListaItensCobranca"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de itens de cobrança</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalListaItensCobranca')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-5">
              <label>Nome do item</label>
              <input v-model="fastItemCobrancaNovo.nome_item" :class="fastItemCobrancaNovo.nome_item ? 'form-control' : 'form-control border border-danger'"/>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3">
              <label>Valor do item</label>
              <money v-model="fastItemCobrancaNovo.valor_item" :class="fastItemCobrancaNovo.valor_item ? 'form-control text-center' : 'form-control text-center border border-danger'"/>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-start align-items-end">
              <button class="btn btn-sm btn-primary" @click="criaNovoItemCobranca()">
                <small>+ Criar novo item</small>
              </button>
            </div>
          </div>  
          <div class="row mt-4">
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Item de cobrança</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Valor padrão</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastItensCobranca.loading">
                  <tr>
                    <td
                      colspan="3"
                      class="text-center"
                    >
                    Carregando...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastItensCobranca.total.length">
                  <tr
                    v-for="e in fastItensCobranca.total"
                    :key="e.id_item_cobranca"
                  >
                    <td class="align-middle text-center">
                      {{ e.nome_item }}
                    </td>   
                    <td class="align-middle text-center">
                      {{ formataPreco(e.valor_item) }}
                    </td>                              
                    <td class="text-center align-middle">                                  
                      <button
                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                        @click="adicionaItemCobranca(e)"
                      >
                        <small>+ Adicionar</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="3"
                      class="text-center"
                    >
                      Nenhum item cadastrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalDefinirPrimeiraParcela"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Defina a primeira parcela</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalDefinirPrimeiraParcela')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4 text-center">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <input
              v-model="dataPrimeiraParcela"
              type="date"
              class="form-control"
              @change="definePrimeiraParcela()"
            >
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfirmarBaixaManual"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Baixa manual</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfirmarBaixaManual')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <p class="text-info">
                A baixa manual deixará a parcela permanentemente definida como paga. A única opção de alteração que estará disponível após uma baixa manual será o upload do comprovante de pagamento.
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-danger mr-2" @click="hideModal('modalConfirmarBaixaManual'); fastTesourariaInvoice.alteracoes_pendentes_pago = true">
                OK, Estou ciente
              </button>
              <button class="btn btn-secondary mr-2" @click="hideModal('modalConfirmarBaixaManual'); fastTesourariaInvoice.pago = false">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalErrosRequisicao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Erros encontrados</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalErrosRequisicao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive">  
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Nº</th>
                    <th>Descrição do erro</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="fastErros.length">
                  <tr v-for="(erro, index) in fastErros" :key="index">
                    <td>{{index + 1}}</td>
                    <td>{{erro}}</td>
                    <td></td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="3">Erro desconhecido. Contate um administrador.</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-secondary mr-2" @click="hideModal('modalErrosRequisicao')">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalErrosFaturaGateway"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Inconsistências encontradas na fatura</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalErrosFaturaGateway')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive">  
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Nº</th>
                    <th>Descrição do erro</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="fastErrosGerencianet.length">
                  <tr v-for="(erro, index) in fastErrosGerencianet" :key="index">
                    <td>{{index + 1}}</td>
                    <td>{{erro}}</td>
                    <td>
                      <button v-if="erro == 'A parcela no FastEAD encontra-se cancelada e no gateway de pagamento está ativa' || erro == 'A parcela no FastEAD encontra-se ativa e no gateway de pagamento está cancelada'" class="btn btn-sm btn-danger" @click="showModal('modalConfirmaCancelarParcela', 'modalCancelarParcela');">
                        <small>Forçar cancelamento</small>
                      </button>
                      <button v-else-if="erro == 'A parcela no FastEAD encontra-se paga e no gateway de pagamento está pendente' || erro == 'A parcela no gateway de pagamento encontra-se paga e no FastEAD está pendente'" class="btn btn-sm btn-danger" @click="exibeModalConfirmarBaixaManual()">
                        <small>Forçar baixa manual</small>
                      </button>
                      <div v-else-if="erro == 'A parcela não está associada ao gateway de pagamento' || erro == 'Charge_id não identificado no Gerencianet'">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <input type="number" class="form-control" v-model="fastChargeId.charge_id"> 
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <button class="btn btn btn-danger" @click="associarChargeId()">
                              <small>Associar charge_id</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="3">Erro desconhecido. Contate um administrador.</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-secondary mr-2" @click="hideModal('modalErrosFaturaGateway')">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCancelarParcela"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Cancelar parcela</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCancelarParcela')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div v-if="fastTesourariaInvoice.parcelas.loading" class="col-12 text-center">
              <b-icon icon="gear-fill" animation="spin"/> Aguarde o carregamento das parcelas
            </div>
            <div v-else-if="fastTesourariaInvoice.parcelas.length" class="col-12">
              <p v-if="fastTesourariaInvoice.parcelas.length > 1" class="text-danger">
                A parcela {{fastTesourariaInvoice.parcela}} da fatura será cancelada. Esse processo é irreversível.
                
              </p>
              <p v-else class="text-danger">
                Cancelando essa parcela a fatura será cancelada. Esse processo é irreversível.
              </p>
            </div>
            <div v-else class="col-12">
              Nenhuma parcela foi identificada. Consulte a equipe de suporte.
            </div>
          </div>
          <div class="row mt-4">
            <div v-if="fastTesourariaInvoice.parcelas.length" class="col-12">
              <button class="btn btn-danger mr-2" @click="showModal('modalConfirmaCancelarParcela', 'modalCancelarParcela');">
                OK, Estou ciente
              </button>
              <button class="btn btn-secondary mr-2" @click="hideModal('modalCancelarParcela');">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfirmaCancelarParcela"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Confirmar o cancelamento?</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfirmaCancelarParcela')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">   
          <div class="row">
            <div class="col-12">
              <label>Informe o motivo do cancelamento: <span class="text-danger">*</span></label>
            </div>
            <div class="col-12">
              <textarea :class="fastTesourariaInvoice.motivo_cancelamento_parcela ? 'form-control' : 'form-control border border-danger text-danger'" v-model="fastTesourariaInvoice.motivo_cancelamento_parcela" maxlength="100"></textarea>
            </div>
          </div>       
          <div class="row mt-4">
            <div v-if="fastTesourariaInvoice.parcelas.length" class="col-12">
              <button class="btn btn-danger mr-2" @click="editarInvoiceParcela('cancelamento_parcela')">
                Sim, confirmo
              </button>
              <button class="btn btn-secondary mr-2" @click="hideModal('modalConfirmaCancelarParcela');">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCancelarInvoice"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Cancelar invoice</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCancelarInvoice')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <p  class="text-danger">
                Cancelando o invoice todas as parcelas pendentes serão canceladas. Esse processo é irreversível.                
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-danger mr-2" @click="showModal('modalConfirmaCancelarInvoice', 'modalCancelarInvoice');">
                OK, Estou ciente
              </button>
              <button class="btn btn-secondary mr-2" @click="hideModal('modalCancelarInvoice');">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfirmaCancelarInvoice"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Confirmar o cancelamento?</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfirmaCancelarInvoice')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">   
          <div class="row">
            <div class="col-12">
              <label>Informe o motivo do cancelamento: <span class="text-danger">*</span></label>
            </div>
            <div class="col-12">
              <textarea :class="fastTesourariaInvoice.motivo_cancelamento_invoice ? 'form-control' : 'form-control border border-danger text-danger'" v-model="fastTesourariaInvoice.motivo_cancelamento_invoice" maxlength="100"></textarea>
            </div>
          </div>       
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-danger mr-2" @click="editarInvoiceParcela('cancelamento_invoice')">
                Sim, confirmo
              </button>
              <button class="btn btn-secondary mr-2" @click="hideModal('modalConfirmaCancelarInvoice');">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard"; //https://binarcode.github.io/vue-form-wizard/#/
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "HomeInternoTesourariaBoletos",
  components: {Pagination, FormWizard, TabContent, WizardStep},
  mixins: [methods],  
  data: function () {
    return {      
      //#region variáveis gerais
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%", 
      fastNavegacao: {
        exibirBoletos: true,
        editaBoleto: false,
        emitirBoleto: false
      },
      //#endregion
      //#region	variáveis exibirBoletos
      exibeFiltro: false,
      fastAnoAtual: new Date().getFullYear(),
      fastMesAtual: (new Date().getMonth() + 1).length > 1 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1).toString(),
      fastStatusFinanceiro: {
        plataformas: [],
        totalCobrado: 0,
        totalPago: 0,
        totalVencer: 0,
        totalVencido: 0,
        loading: true
      },
      fastInvoice: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastFiltro: {
        plataforma: {
          lista: [],
          escolhida: ""
        },
        aluno: {
          lista: [],
          escolhido: ""
        },
        responsavel: {
          lista: [],
          escolhido: ""
        },
        anoVencimento: {
          lista: ['Todos', (new Date().getFullYear()) - 3, (new Date().getFullYear()) - 2, (new Date().getFullYear()) - 1, (new Date().getFullYear()), (new Date().getFullYear()) + 1, (new Date().getFullYear()) + 2, (new Date().getFullYear()) + 3],
          escolhido: (new Date().getFullYear())
        },
        mesVencimento: {
          lista: ['Todos', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          escolhido: (new Date().getMonth() + 1)
        },
        anoRegistro: {
          lista: ['Todos', (new Date().getFullYear()) - 1, (new Date().getFullYear()), (new Date().getFullYear()) + 1],
          escolhido: 'Todos'
        },
        mesRegistro: {
          lista: ['Todos', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          escolhido: 0
        },
        diaRegistro: {
          lista: [],
          escolhido: ""
        },
        diaVencimento: {
          lista: [],
          escolhido: ""
        },
        status: {
          escolhido: ""
        },
        data_alterada: false
      },
      // Invoice
      fastTesourariaInvoice: {
        ativo: "",
        cancelado_invoice: "",
        cancelado_parcela: "",
        cpf_aluno: "",
        cpf_responsavel: "",
        data_cancelamento_invoice: "",
        data_cancelamento_parcela: "",
        data_pagamento: "",
        data_registro: "",
        data_vencimento: "",
        descontos: "",
        id_usuario_cancelamento_invoice: "",
        id_usuario_cancelamento_parcela: "",
        id_invoice: "",
        id_matricula: "",
        id_plataforma: 0,
        logo_plataforma: "",
        nome_aluno: "",
        nome_plataforma: "",
        nome_responsavel: "",
        nr_parcelas: "",
        obs: "",
        pago: "",
        parcela: "",
        plataforma_mae: "",
        status_plataforma: "",
        subtotal: "",
        total: "",
        valor_parcela_total: "",
        valor_parcela: "",
        taxa_boleto: "",
        taxa_cartao: "",
        outras_taxas: "",
        acrescimo: "",
        tipo: "",
        path_boleto: "",
        path_comprovante_pagamento: "",
        motivo_cancelamento_invoice: "",
        motivo_cancelamento_parcela: "",
        aluno: {
          id_usuario: "",
          id_pessoa: 0,
          email: "",
          first_name: "",
          last_name: "",
          cpf_cnpj: "",
          datanascimento_abertura: null,
          sexo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: "",
          telefone: "",
          loading: true,
        },
        responsavel: {
          id_usuario: "",
          id_pessoa: 0,
          email: "",
          first_name: "",
          last_name: "",
          cpf_cnpj: "",
          datanascimento_abertura: null,
          sexo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: "",
          telefone: "",
          loading: true,
        },
        itensCobranca: [],
        itensLoading: true,
        parcelasLoading: true,
        alteracoes_pendentes_parcela: false,
        alteracoes_pendentes_vencimento: false,
        alteracoes_pendentes_pago: false,
        parcelas: [],
      },
      fastChargeId: {
        charge_id: 0,
        parcela: 0,
        id_invoice: 0,
        id_plataforma: 0
      },
      //#endregion
      //#region	variáveis editaBoleto
      fastSteps: {
        currentStep: 0,
        step1: {
          pessoas: []
        },
        step2: {
          itens: [],
          total: 0.00
        },
        step3: {
          plano: {
            id_plano_pagamento: 0,
            nome_plano: "",
            obs: "",
            periocidade_cada_unidade: "",
            dia_mes: "",
            tempo_determinado: "",
            parcelas: [],
            loading: true
          },
          tipo: {
            forma_pagamento: "",
            id_tipo_pagamento: 0,
            nome_tipo_pagamento: "",
            forma_pagamento: "",
            id_plano_conta: "",
            loading: true
          },
          invoice: {
            id_plataforma: 0,
            id_invoice: 0,
            data_registro: "",
            id_usuario_gerou: 0,
            id_matricula: 0,
            nome_aluno: "",
            nome_responsavel: "",
            cpf_responsavel: "",
            cpf_aluno: "",
            cep_responsavel: "",
            endereco_responsavel_numero: "",
            endereco_responsavel_complemento: "",
            subtotal: 0.00,
            desconto_total: 0.00,
            acrescimo: 0.00,
            total: 0.00,
            ativo: "",
            cancelado: "",
            motivo_cancelamento: "",
            id_usuario_cancelamento: 0,
            data_cancelamento: "",
            obs: "",
            id_plano_pagamento: "",
            taxa_boleto: 0.00,
            taxa_cartao: 0.00,
            outras_taxas: 0.00,
            id_gateway: 0,
            id_pessoa_aluno: 0,
            tipo: "",
            parcelas: []
          },
        }
      },
      fastItensCobranca: {
        total: [],
        loading: true
      },
      fastItemCobrancaNovo: {
        id_item_cobranca: 0,
        nome_item: "",
        valor_item: 0.00,
        quantidade: 1,
        id_plataforma: this.$route.params.id_plataforma
      },
      // Lista de pessoas
      fastPessoasTotal: [],
      fastPessoasFiltro: [],
      fastPessoasPaginacao: [],
      fastPessoasLoading: [],
      // Pagamento
      fastSecretariaPlanoPagamento: [],
      fastSecretariaTipoPagamento: [],
      dataPrimeiraParcela: "",
      // Link clipboard
      fastLinkClipboard: false,
      // Upload do comprovante
      optionsArquivo: {
        target: settings.endApiFastEad + "api/fast_tesouraria_invoice_parcela/atualiza_comprovante",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_invoice: 0,
          parcela: 0
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",            
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Erros
      fastErros: [],
      // Inconsistências
      fastErrosGerencianet: [],
      fastErrosGerencianetLoading: true,
      fastPagtoIdentifiedGerencianet: false,
      // Comissão
      fastComissaoSchema: {
        total: [],
        loading: true
      },
      fastComissaoSchemaDeterminacoes: {
        total: [],
        loading: true
      },
      fastTesourariaInvoiceComissao: {
        total: [],
        loading: true
      },
      fastTesourariaNovoBoleto: {
        loading: false
      }
      //#endregion
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (
            this.$store.state.fastPlataformaModulos.modulo_secretaria
          ) {
            this.getFastHoraServidor()
            .then(() => {
              
              // Verifica gateway de pagamento
              this.getPlataformaGateway(this.$route.params.id_plataforma)
              // int id_plataforma, string ano_vencimento, string mes_vencimento, string ano_registro, string mes_registro
              
              this.alteraDataFiltro();
              //this.getInvoiceVencimentos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')
              //this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')

              if (this.getUrlParameter("idInvoice")) {
                if (this.getUrlParameter("parcela")) this.getInvoiceGerado(this.$route.params.id_plataforma, parseInt(this.getUrlParameter("idInvoice")), parseInt(this.getUrlParameter("parcela")))
                else
                  this.getInvoiceGerado(this.$route.params.id_plataforma, parseInt(this.getUrlParameter("idInvoice")))
              }

              
            })            

            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    //#region Gateway
    async getPlataformaGateway(id_plataforma){
      this.promiseGetFastApi("api/fast_plataforma_gateway/lista", `id_plataforma=${id_plataforma}`)
      .then((res) => {
        console.log("getPlataformaGateway", res)
        if (!res.length) {         
          if (this.$store.state.fastPermissoes.administrador == "S") {
            this.$toast.open({
              message: "<div>Nenhum gateway de pagamento configurado.</div> <div>Clique aqui e configure o gateway interno nos parâmetros da plataforma.</div>",
              type: "error",
              duration: 60000,
              dismissible: true,
              position: "bottom-right",
              onClick: this.redirecionaPersonalizarPlataforma
            });
          } else {
            this.$toast.open({
              message: "<div>Nenhum gateway de pagamento configurado.</div> <div>Consulte um administrador da plataforma.</div>",
              type: "error",
              duration: 60000,
              dismissible: true,
              position: "bottom-right"
            });
          }
          
        }
      })
      .catch(e => {
        this.exibeToasty(e, "error")
      })
    },
    redirecionaPersonalizarPlataforma(){
      this.$router.push('/plataforma/' + this.$route.params.id_plataforma +'/admin/personalizar-plataforma')
    },
    //#endregion
    
    //#region Exibir boletos
    async getInvoiceVencimentos(id_plataforma, ano_vencimento, mes_vencimento, ano_registro, mes_registro){
      this.fastStatusFinanceiro.loading = true
      this.fastStatusFinanceiro.plataformas = []
      this.fastStatusFinanceiro.totalCobrado = 0
      this.fastStatusFinanceiro.totalPago  = 0
      this.fastStatusFinanceiro.totalVencer = 0
      this.fastStatusFinanceiro.totalVencido = 0
      this.fastFiltro.data_alterada = false
      this.promiseGetFastApi("api/fast_tesouraria_invoice/lista_vencimentos", `id_plataforma=${id_plataforma}&ano_vencimento=${ano_vencimento}&mes_vencimento=${mes_vencimento}&ano_registro=${ano_registro}&mes_registro=${mes_registro}`)
      .then((res) => {
        console.log("getInvoiceVencimentos", res)
        if (res.length) {
          this.fastStatusFinanceiro.plataformas = res
          this.calculaStatusFinanceiro()
        } 

        this.fastStatusFinanceiro.loading = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastStatusFinanceiro.loading = false
      })
    },
    calculaStatusFinanceiro(id_plataforma){
      if (id_plataforma) {
        this.fastStatusFinanceiro.plataformas.forEach(p => {
          if (p.id_plataforma == id_plataforma) {
            this.fastStatusFinanceiro.totalCobrado += p.valor_total_cobrado
            this.fastStatusFinanceiro.totalPago += p.valor_total_pago
            this.fastStatusFinanceiro.totalVencer += p.valor_total_vencer
            this.fastStatusFinanceiro.totalVencido += p.valor_total_vencido
          }
        })
      } else {
        this.fastStatusFinanceiro.plataformas.forEach(p => {
          this.fastStatusFinanceiro.totalCobrado += p.valor_total_cobrado
          this.fastStatusFinanceiro.totalPago += p.valor_total_pago
          this.fastStatusFinanceiro.totalVencer += p.valor_total_vencer
          this.fastStatusFinanceiro.totalVencido += p.valor_total_vencido
        })
      }
    },
    retornaNomePlataformas(){
      let array = this.fastStatusFinanceiro.plataformas.map(e => e.nome_plataforma)
      return array.join(", ")
    },
    getInvoiceBoletos(id_plataforma, ano_vencimento, mes_vencimento, ano_registro, mes_registro){
      this.fastInvoice.loading = true
      this.fastFiltro.plataforma.lista = []
      this.fastFiltro.aluno.lista = []
      this.fastFiltro.responsavel.lista = []
      this.fastInvoice.total = []            
      this.fastInvoice.filtro = []
      this.fastFiltro.plataforma.escolhida = ""
      this.fastFiltro.aluno.escolhida = ""
      this.fastFiltro.responsavel.escolhida = ""
      this.fastFiltro.status.escolhido = ""
      this.fastFiltro.diaRegistro.escolhido = ""
      this.fastFiltro.diaVencimento.escolhido = ""
      this.fastFiltro.data_alterada = false
      this.promiseGetFastApi("api/fast_tesouraria_invoice/lista_boletos", `id_plataforma=${id_plataforma}&ano_vencimento=${ano_vencimento}&mes_vencimento=${mes_vencimento}&ano_registro=${ano_registro}&mes_registro=${mes_registro}`)
        .then((res) => {          
          console.log("getInvoiceBoletos", res)
          if (res.length) {
            this.fastInvoice.total = res            
            this.fastInvoice.filtro = res  

            // Filtro de plataformas
            this.fastFiltro.plataforma.lista = Array.from(
              new Set(
                res.map((p) => {
                  return p.nome_plataforma;
                })
              )
            );

            // Filtro de alunos
            this.fastFiltro.aluno.lista = Array.from(
              new Set(
                res.map((p) => {
                  return p.nome_aluno.trim();
                })
              )
            ).sort((a, b) => a.localeCompare(b))

            // Filtro de responsáveis
            this.fastFiltro.responsavel.lista = Array.from(
              new Set(
                res.map((p) => {
                  return p.nome_responsavel.trim();
                })
              )
            ).sort((a, b) => a.localeCompare(b))

            // Filtro de dia do registro
            this.fastFiltro.diaRegistro.lista = Array.from(
              new Set(
                res.map((p) => {
                  if (p.data_registro) return this.formataDataT(p.data_registro)
                })
              )
            ).sort((a, b) => a.localeCompare(b))

            // Filtro de dia do vencimento
            this.fastFiltro.diaVencimento.lista = Array.from(
              new Set(
                res.map((p) => {
                  if (p.data_vencimento) return this.formataDataT(p.data_vencimento)
                })
              )
            ).sort((a, b) => a.localeCompare(b))

          }          
          
          this.fastInvoice.loading = false
          

        })
        .catch((e) => {
          console.log(e);
          this.fastInvoice.loading = false
        });
    },
    aplicaFiltro(){
      this.fastInvoice.loading = true
      this.fastInvoice.filtro = this.fastInvoice.total

      if (this.fastFiltro.plataforma.escolhida) {
        this.fastInvoice.filtro = this.fastInvoice.total.filter(
          (r) => {
            return (
              this.fastFiltro.plataforma.escolhida == r.nome_plataforma
            );
          }
        );
      } 

      if (this.fastFiltro.aluno.escolhido) {
        this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
          (r) => {
            return (
              this.fastFiltro.aluno.escolhido.trim() == r.nome_aluno.trim()
            );
          }
        );
      } 

      if (this.fastFiltro.responsavel.escolhido) {
        this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
          (r) => {
            return (
              this.fastFiltro.responsavel.escolhido.trim() == r.nome_responsavel.trim()
            );
          }
        );
      } 

      if (this.fastFiltro.status.escolhido) {
        switch (this.fastFiltro.status.escolhido) {
          case 'P':
            // Pago
            this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
              (r) => {
                return (
                  r.pago
                );
              }
            );
            break;
          case 'A':
            // Pendente
            this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
              (r) => {
                return (
                  !this.verificaVencimento(r.data_vencimento) && !r.pago
                );
              }
            );
            break;
          case 'V':
            // Vencido
            this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
              (r) => {
                return (
                  this.verificaVencimento(r.data_vencimento) && !r.pago
                );
              }
            );
            break;
          case 'C':
            // Cancelado
            this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
              (r) => {
                return (
                  r.cancelado_parcela
                );
              }
            );
            break;
        
          default:
            break;
        }
        
      } 

      if (this.fastFiltro.diaRegistro.escolhido) {
        this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
          (r) => {
            return (
              this.fastFiltro.diaRegistro.escolhido == this.formataDataT(r.data_registro)
            );
          }
        );
      } 

      if (this.fastFiltro.diaVencimento.escolhido) {
        this.fastInvoice.filtro = this.fastInvoice.filtro.filter(
          (r) => {
            return (
              this.fastFiltro.diaVencimento.escolhido == this.formataDataT(r.data_vencimento)
            );
          }
        );
      } 

      this.fastInvoice.loading = false
    },
    alteraDataFiltro(){
      // int id_plataforma, string ano_vencimento, string mes_vencimento, string ano_registro, string mes_registro  
      let mesVencimento = ""
      let anoVencimento = ""

      if (this.fastFiltro.anoVencimento.escolhido != "Todos") anoVencimento = this.fastFiltro.anoVencimento.escolhido
      if (this.fastFiltro.mesVencimento.escolhido) {
        mesVencimento = this.fastFiltro.mesVencimento.escolhido
        mesVencimento = mesVencimento < 10 ? '0' + (mesVencimento).toString() : mesVencimento
      }

      let mesRegistro = ""
      let anoRegistro = ""

      if (this.fastFiltro.anoRegistro.escolhido != "Todos") anoRegistro = this.fastFiltro.anoRegistro.escolhido
      if (this.fastFiltro.mesRegistro.escolhido) {
        mesRegistro = this.fastFiltro.mesRegistro.escolhido
        mesRegistro = mesRegistro < 10 ? '0' + (mesRegistro).toString() : mesRegistro
      }

      this.getInvoiceBoletos(this.$route.params.id_plataforma, anoVencimento, mesVencimento, anoRegistro , mesRegistro)
      this.getInvoiceVencimentos(this.$route.params.id_plataforma, anoVencimento, mesVencimento, anoRegistro , mesRegistro)
      
    },
    verificaVencimento(dataVencimento){
      let dataAtual = this.$store.state.fastDataAtualServidor.toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' )   
      if (dataVencimento) {
        dataVencimento = new Date(dataVencimento.split("T")[0]).toISOString().slice(0,10);   
      }
      if (dataAtual > dataVencimento) {
        return true
      }
      else {
        return false
      }
      
    },
    //#endregion

    //#region Editar boleto
    exibeModalEditarBoleto(boleto){
      //console.log("boleto", boleto)
      this.fastLinkClipboard = false
      this.fastTesourariaInvoice.alteracoes_pendentes_pago = false
      this.fastTesourariaInvoice.alteracoes_pendentes_vencimento = false

      this.fastTesourariaInvoice.path_boleto = boleto.path_boleto ? boleto.path_boleto : "Nenhum boleto registrado"
      this.fastTesourariaInvoice.id_pessoa_aluno = boleto.id_pessoa_aluno
      this.fastTesourariaInvoice.id_pessoa_responsavel = boleto.id_pessoa_responsavel
      this.fastTesourariaInvoice.ativo = boleto.ativo
      this.fastTesourariaInvoice.cancelado_parcela = boleto.cancelado_parcela
      this.fastTesourariaInvoice.cancelado_invoice = boleto.cancelado_invoice
      this.fastTesourariaInvoice.cpf_aluno = boleto.cpf_aluno
      this.fastTesourariaInvoice.cpf_responsavel = boleto.cpf_responsavel
      this.fastTesourariaInvoice.data_pagamento = boleto.data_pagamento
      this.fastTesourariaInvoice.data_registro = boleto.data_registro
      this.fastTesourariaInvoice.data_vencimento = boleto.data_vencimento ? boleto.data_vencimento.split("T")[0] : ""
      this.fastTesourariaInvoice.descontos = boleto.descontos
      this.fastTesourariaInvoice.id_invoice = boleto.id_invoice
      this.fastTesourariaInvoice.id_matricula = boleto.id_matricula
      this.fastTesourariaInvoice.id_plataforma = boleto.id_plataforma
      this.fastTesourariaInvoice.logo_plataforma = boleto.logo_plataforma
      this.fastTesourariaInvoice.nome_aluno = boleto.nome_aluno
      this.fastTesourariaInvoice.nome_plataforma = boleto.nome_plataforma
      this.fastTesourariaInvoice.nome_responsavel = boleto.nome_responsavel
      this.fastTesourariaInvoice.nr_parcelas = boleto.nr_parcelas
      this.fastTesourariaInvoice.obs = boleto.obs
      this.fastTesourariaInvoice.pago = boleto.pago
      this.fastTesourariaInvoice.parcela = boleto.parcela
      this.fastTesourariaInvoice.plataforma_mae = boleto.plataforma_mae
      this.fastTesourariaInvoice.status_plataforma = boleto.status_plataforma
      this.fastTesourariaInvoice.subtotal = boleto.subtotal
      this.fastTesourariaInvoice.total = boleto.total
      this.fastTesourariaInvoice.valor_parcela_total = boleto.valor_parcela_total
      this.fastTesourariaInvoice.tipo = boleto.tipo
      this.fastTesourariaInvoice.path_comprovante_pagamento = boleto.path_comprovante_pagamento     
      this.fastTesourariaInvoice.log_pagto_auditoria = boleto.log_pagto_auditoria  
      this.fastTesourariaInvoice.motivo_cancelamento_invoice = boleto.motivo_cancelamento_invoice 
      this.fastTesourariaInvoice.motivo_cancelamento_parcela = boleto.motivo_cancelamento_parcela
      this.fastTesourariaInvoice.data_cancelamento_invoice = boleto.data_cancelamento_invoice 
      this.fastTesourariaInvoice.data_cancelamento_parcela = boleto.data_cancelamento_parcela
      this.fastTesourariaInvoice.id_usuario_cancelamento_invoice = boleto.id_usuario_cancelamento_invoice 
      this.fastTesourariaInvoice.id_usuario_cancelamento_parcela = boleto.id_usuario_cancelamento_parcela

      this.fastTesourariaInvoice.valor_parcela = boleto.valor_parcela
      this.fastTesourariaInvoice.taxa_boleto = boleto.taxa_boleto
      this.fastTesourariaInvoice.taxa_cartao = boleto.taxa_cartao
      this.fastTesourariaInvoice.outras_taxas = boleto.outras_taxas
      this.fastTesourariaInvoice.acrescimo = boleto.acrescimo

      // Busca itens de cobrança
      if (this.fastTesourariaInvoice.tipo == "I" || this.fastTesourariaInvoice.tipo == "L") {
        this.fastTesourariaInvoice.itensLoading = true
        this.promiseGetFastApi("api/fast_tesouraria_invoice_itens_cobranca/lista", `id_invoice=${boleto.id_invoice}&id_plataforma=${this.$route.params.id_plataforma}`)
        .then((res) => {
          //console.log("api/fast_tesouraria_invoice_itens_cobranca/lista", res)  
          if (res.length) {
            this.fastTesourariaInvoice.itensCobranca = res
          } else {
            this.fastTesourariaInvoice.itensCobranca = []
          }
          this.fastTesourariaInvoice.itensLoading = false
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.fastTesourariaInvoice.itensLoading = false
        })
      } else {
        this.fastTesourariaInvoice.itensCobranca = []
      }

      // Dados do aluno
      if (this.fastTesourariaInvoice.id_pessoa_aluno){
        this.fastTesourariaInvoice.aluno.loading = true
        this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", `id_pessoa=${this.fastTesourariaInvoice.id_pessoa_aluno}&id_plataforma=${this.$route.params.id_plataforma}`)
        .then((res) => {
          //console.log("api/fast_plataforma_usuario/busca_pessoa_secretaria", res)  
          let obj = JSON.parse(res);
          if (!obj.error) {
            obj.loading = false
            this.fastTesourariaInvoice.aluno = obj
          } else {
            this.exibeToasty("Erro ao busca informações do responsável", "error")
            this.fastTesourariaInvoice.aluno = {
              id_usuario: "",
              id_pessoa: 0,
              email: "",
              first_name: "",
              last_name: "",
              cpf_cnpj: "",
              datanascimento_abertura: null,
              sexo: "",
              uf: "",
              cep: "",
              logradouro: "",
              bairro: "",
              cidade: "",
              complemento: "",
              numero: "",
              telefone: "",
              loading: false,
            }
          }
          this.fastTesourariaInvoice.aluno.loading = false
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.fastTesourariaInvoice.aluno.loading = false
        })
      } else {
        this.exibeToasty("Id_pessoa não registrado do aluno", "error")
        this.fastTesourariaInvoice.aluno = {
          id_usuario: "",
          id_pessoa: 0,
          email: "",
          first_name: "",
          last_name: "",
          cpf_cnpj: "",
          datanascimento_abertura: null,
          sexo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: "",
          telefone: "",
          loading: false,
        }
      }
      
      // Dados do responsável
      if (this.fastTesourariaInvoice.id_pessoa_responsavel){
        this.fastTesourariaInvoice.responsavel.loading = true
        this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", `id_pessoa=${this.fastTesourariaInvoice.id_pessoa_responsavel}&id_plataforma=${this.$route.params.id_plataforma}`)
        .then((res) => {
          //console.log("api/fast_plataforma_usuario/busca_pessoa_secretaria", res)  
          let obj = JSON.parse(res);
          if (!obj.error) {
            obj.loading = false
            this.fastTesourariaInvoice.responsavel = obj
          } else {
            this.exibeToasty("Erro ao busca informações do responsável", "error")
            this.fastTesourariaInvoice.responsavel = {
              id_usuario: "",
              id_pessoa: 0,
              email: "",
              first_name: "",
              last_name: "",
              cpf_cnpj: "",
              datanascimento_abertura: null,
              sexo: "",
              uf: "",
              cep: "",
              logradouro: "",
              bairro: "",
              cidade: "",
              complemento: "",
              numero: "",
              telefone: "",
              loading: false,
            }
          }
          this.fastTesourariaInvoice.responsavel.loading = false
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.fastTesourariaInvoice.responsavel.loading = false
        })
      } else {
        this.exibeToasty("Id_pessoa não registrado do responsável", "error")
        this.fastTesourariaInvoice.responsavel = {
          id_usuario: "",
          id_pessoa: 0,
          email: "",
          first_name: "",
          last_name: "",
          cpf_cnpj: "",
          datanascimento_abertura: null,
          sexo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: "",
          telefone: "",
          loading: false,
        }
      }

      // Dados de comissão
      this.fastTesourariaInvoiceComissao.loading = true      
      this.promiseGetFastApi("api/fast_tesouraria_invoice_schema_comissao/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_invoice=${boleto.id_invoice}`)
      .then((res) => {
        if (res.length) {
          this.fastTesourariaInvoiceComissao.total = res
          this.exibeToasty("Essa fatura possui comissão", "info")
        } else {
          this.fastTesourariaInvoiceComissao.total = []
        }
        this.fastTesourariaInvoiceComissao.loading = false          
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastTesourariaInvoiceComissao.loading = false
      })

      this.getParcelasInvoice(boleto.id_invoice)

      this.alteraFastNavegacao('editarBoleto')

      // Retorna inconsistências fastead/gerencianet
      this.getInconsistenciasGerenciaNet(this.fastTesourariaInvoice.id_plataforma, boleto.id_invoice, boleto.parcela)
    },
    exibeModalEditarOutroBoleto(boleto){
      this.fastLinkClipboard = false
      this.fastTesourariaInvoice.alteracoes_pendentes_pago = false      
      this.fastTesourariaInvoice.alteracoes_pendentes_vencimento = false

      this.fastTesourariaInvoice.path_boleto = boleto.path_boleto ? boleto.path_boleto : "Nenhum boleto registrado"
      this.fastTesourariaInvoice.data_vencimento = boleto.data_vencimento ? boleto.data_vencimento.split("T")[0] : ""
      this.fastTesourariaInvoice.descontos = boleto.descontos
      this.fastTesourariaInvoice.parcela = boleto.parcela
      this.fastTesourariaInvoice.valor_parcela = boleto.valor_parcela
      this.fastTesourariaInvoice.taxa_boleto = boleto.taxa_boleto
      this.fastTesourariaInvoice.taxa_cartao = boleto.taxa_cartao
      this.fastTesourariaInvoice.outras_taxas = boleto.outras_taxas
      this.fastTesourariaInvoice.acrescimo = boleto.acrescimo
      this.fastTesourariaInvoice.pago = boleto.pago

      this.fastTesourariaInvoice.cancelado_invoice = boleto.cancelado_invoice 
      this.fastTesourariaInvoice.cancelado_parcela = boleto.cancelado_parcela
      this.fastTesourariaInvoice.path_comprovante_pagamento = boleto.path_comprovante_pagamento     
      this.fastTesourariaInvoice.log_pagto_auditoria = boleto.log_pagto_auditoria  
      this.fastTesourariaInvoice.motivo_cancelamento_invoice = boleto.motivo_cancelamento_invoice 
      this.fastTesourariaInvoice.motivo_cancelamento_parcela = boleto.motivo_cancelamento_parcela
      this.fastTesourariaInvoice.data_cancelamento_invoice = boleto.data_cancelamento_invoice 
      this.fastTesourariaInvoice.data_cancelamento_parcela = boleto.data_cancelamento_parcela
      this.fastTesourariaInvoice.id_usuario_cancelamento_invoice = boleto.id_usuario_cancelamento_invoice 
      this.fastTesourariaInvoice.id_usuario_cancelamento_parcela = boleto.id_usuario_cancelamento_parcela

      this.getParcelasInvoice(boleto.id_invoice)

      // Retorna inconsistências fastead/gerencianet
      this.getInconsistenciasGerenciaNet(this.fastTesourariaInvoice.id_plataforma, boleto.id_invoice, boleto.parcela)
    },
    retornaParcelaComissao(parcela){
      let retorno = ""
      let valor = 0.00
      this.fastTesourariaInvoiceComissao.total.forEach(e => {
        if (e.parcela == parcela.parcela) {
          valor += e.valor
        }
      })
      if (valor > 0) {
        retorno = ` - R$ ${this.formataPreco(valor)} (comissão)`
      }
      return retorno
    },
    retornaTotalComissao(){
      let retorno = ""
      let valor = 0.00
      this.fastTesourariaInvoiceComissao.total.forEach(e => {
        valor += e.valor
      })
      if (valor > 0) {
        retorno = ` - R$ ${this.formataPreco(valor)} (comissão) = R$ ${this.formataPreco(this.fastTesourariaInvoice.total - valor)}`
      }
      return retorno
    },
    gerarBoletoComissaoManual(){
      this.fastTesourariaNovoBoleto.loading = true
      const obj = {
        id_plataforma: this.fastTesourariaInvoice.id_plataforma,
        id_invoice: this.fastTesourariaInvoice.id_invoice,
        parcela: this.fastTesourariaInvoice.parcela
      }
      this.promisePostFastApi(obj, "api/fast_tesouraria_invoice_parcela/gera_boleto_comissao")
        .then(res => {
          console.log("gera_boleto_comissao", res)
          if (res.length){
            const json = JSON.parse(res)            
            if (json.status) {
              this.exibeToasty("Boleto gerado com sucesso", "success")
              this.exibeToasty("Email enviado ao destinatário", "success")
              this.fastTesourariaInvoice.path_boleto = json.path_boleto
            } else {
              if (json.description.split(",").length) {
                this.fastErros = json.description.split(",")
              } else {
                this.fastErros.push(json.description)
                console.log("this.fastErros", this.fastErros)
              }            
              this.showModal('modalErrosRequisicao')
            }
          }
          this.fastTesourariaNovoBoleto.loading = false          
        }).catch((e) => {
          this.exibeToasty(e, "error")
          this.fastTesourariaNovoBoleto.loading = false
        })
    },
    async getParcelasInvoice(id_invoice){
      this.fastTesourariaInvoice.parcelasLoading = true
      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista", `id_invoice=${id_invoice}`)
      .then((res) => {
        console.log("api/fast_tesouraria_invoice_parcela/lista", res)  
        if (res.length) {
          this.fastTesourariaInvoice.parcelas = res
        } else {
          this.fastTesourariaInvoice.parcelas = []
        }
        this.fastTesourariaInvoice.parcelasLoading = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastTesourariaInvoice.parcelasLoading = false
      })
    },
    async editarInvoiceParcela(tipo){
      this.fastErros = []      
      this.hideModal("modalErrosFaturaGateway")
      
      let obj = {}
      if (tipo == "data_vencimento") {
        this.$store.state.fastCarregando = true
        obj = {
          fast_tesouraria_invoice: {
            id_invoice: this.fastTesourariaInvoice.id_invoice,
            id_plataforma: this.fastTesourariaInvoice.id_plataforma
          },
          fast_tesouraria_invoice_parcela: {
            id_invoice: this.fastTesourariaInvoice.id_invoice,
            parcela: this.fastTesourariaInvoice.parcela,  
            data_vencimento: this.fastTesourariaInvoice.data_vencimento
          }
        }

        this.promisePostFastApi(obj, "api/fast_tesouraria_invoice_parcela/atualiza_data_vencimento")
        .then((res) => {
          console.log("editarInvoiceParcela", res)
          let json = JSON.parse(res)
          if (!json.error) {            
            this.fastTesourariaInvoice.alteracoes_pendentes_vencimento = false
            this.exibeToasty("Parcela alterada com sucesso", "success");
            this.getParcelasInvoice(this.fastTesourariaInvoice.id_invoice)      
            this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')      
          } else {            
            if (json.description.split(",").length) {
              this.fastErros = json.description.split(",")
            } else {
              this.fastErros.push(json.description)
              console.log("this.fastErros", this.fastErros)
            }
            this.showModal("modalErrosRequisicao")
          }
          this.$store.state.fastCarregando = false
          
        })
        .catch((e) => {
          this.fastErros.push(e)
          this.showModal("modalErrosRequisicao")
          this.$store.state.fastCarregando = false
        });
      } else if (tipo == "baixa_manual") {
        this.$store.state.fastCarregando = true
        obj = {
          fast_tesouraria_invoice: {
            id_invoice: this.fastTesourariaInvoice.id_invoice,
            id_plataforma: this.fastTesourariaInvoice.id_plataforma
          },
          fast_tesouraria_invoice_parcela: {
            id_invoice: this.fastTesourariaInvoice.id_invoice,
            parcela: this.fastTesourariaInvoice.parcela,  
            pago: true
          }
        }

        this.promisePostFastApi(obj, "api/fast_tesouraria_invoice_parcela/atualiza_baixa_manual")
        .then((res) => {
          console.log("editarInvoiceParcela", res)
          let json = JSON.parse(res)
          if (!json.error) {            
            this.fastTesourariaInvoice.alteracoes_pendentes_pago = false
            this.exibeToasty("Parcela alterada com sucesso", "success");
            this.getParcelasInvoice(this.fastTesourariaInvoice.id_invoice)
            this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')
          } else {            
            if (json.description.split(",").length) {
              this.fastErros = json.description.split(",")
            } else {
              this.fastErros.push(json.description)
              console.log("this.fastErros", this.fastErros)
            }
            this.showModal("modalErrosRequisicao")
          }
          this.$store.state.fastCarregando = false
          
        })
        .catch((e) => {
          this.fastErros.push(e)
          this.showModal("modalErrosRequisicao")
          this.$store.state.fastCarregando = false
        });
      } else if (tipo == "cancelamento_parcela") {
        if (!this.fastTesourariaInvoice.motivo_cancelamento_parcela) {
          this.exibeToasty("É necessário informar o motivo", "error")
        } else {
          this.$store.state.fastCarregando = true
          obj = {
            fast_tesouraria_invoice: {
              id_invoice: this.fastTesourariaInvoice.id_invoice,
              id_plataforma: this.fastTesourariaInvoice.id_plataforma,
              motivo_cancelamento_novo: this.fastTesourariaInvoice.motivo_cancelamento_parcela
            },
            fast_tesouraria_invoice_parcela: {
              id_invoice: this.fastTesourariaInvoice.id_invoice,
              parcela: this.fastTesourariaInvoice.parcela,
              motivo_cancelamento: this.fastTesourariaInvoice.motivo_cancelamento_parcela
            }
          }

          this.promisePostFastApi(obj, "api/fast_tesouraria_invoice_parcela/cancela")
          .then((res) => {
            console.log("cancelamento_parcela", res)
            let json = JSON.parse(res)
            if (!json.error) {            
              this.exibeToasty("Parcela cancelada com sucesso", "success");
              this.hideModal("modalConfirmaCancelarParcela")
              this.hideModal("modalCancelarParcela")    

              this.getParcelasInvoice(this.fastTesourariaInvoice.id_invoice)
              this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')
              this.fastTesourariaInvoice.cancelado_parcela = true
            } else {            
              if (json.description.split(",").length) {
                this.fastErros = json.description.split(",")
              } else {
                this.fastErros.push(json.description)
                console.log("this.fastErros", this.fastErros)
              }
              this.hideModal("modalConfirmaCancelarParcela")  
              this.hideModal("modalCancelarParcela") 
              this.showModal("modalErrosRequisicao")
              
            }
            this.getInconsistenciasGerenciaNet(this.fastTesourariaInvoice.id_plataforma, this.fastTesourariaInvoice.id_invoice, this.fastTesourariaInvoice.parcela)
            this.$store.state.fastCarregando = false
            
          })
          .catch((e) => {
            this.fastErros.push(e)
            this.showModal("modalCancelarParcela")
            this.$store.state.fastCarregando = false
          });
        }        
      } else if (tipo == "cancelamento_invoice") {
        if (!this.fastTesourariaInvoice.motivo_cancelamento_invoice) {
          this.exibeToasty("É necessário informar o motivo", "error")
        } else {
          this.$store.state.fastCarregando = true
          obj = {
            fast_tesouraria_invoice: {
              id_invoice: this.fastTesourariaInvoice.id_invoice,
              id_plataforma: this.fastTesourariaInvoice.id_plataforma,
              motivo_cancelamento_novo: this.fastTesourariaInvoice.motivo_cancelamento_invoice
            }
          }

          this.promisePostFastApi(obj, "api/fast_tesouraria_invoice/cancela")
          .then((res) => {
            console.log("cancelamento_invoice", res)
            let json = JSON.parse(res)
            if (!json.error) {            
              this.exibeToasty("Invoice cancelado com sucesso", "success");
              this.hideModal("modalConfirmaCancelarInvoice")
              this.hideModal("modalCancelarInvoice")    
              this.getParcelasInvoice(this.fastTesourariaInvoice.id_invoice)
              this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')
              this.fastTesourariaInvoice.cancelado_invoice = true
              this.fastTesourariaInvoice.cancelado_parcela = true
            } else {            
              if (json.description.split(",").length) {
                this.fastErros = json.description.split(",")
              } else {
                this.fastErros.push(json.description)
                console.log("this.fastErros", this.fastErros)
              }
              this.hideModal("modalConfirmaCancelarInvoice")
              this.hideModal("modalCancelarInvoice") 
              this.showModal("modalErrosRequisicao")
            }
            this.getInconsistenciasGerenciaNet(this.fastTesourariaInvoice.id_plataforma, this.fastTesourariaInvoice.id_invoice, this.fastTesourariaInvoice.parcela)
            this.$store.state.fastCarregando = false
            
          })
          .catch((e) => {
            this.fastErros.push(e)
            this.showModal("modalCancelarInvoice")
            
            this.$store.state.fastCarregando = false
          });
        }        
      } 
    },
    async associarChargeId(){
      this.hideModal("modalErrosFaturaGateway")
      this.fastErros = []
      let erros = []
      if (!this.fastChargeId.charge_id) erros.push("Insira o charge_id")
      if (!this.fastTesourariaInvoice.parcela) erros.push("Parcela não identificada")
      if (!this.fastTesourariaInvoice.id_invoice) erros.push("Id_invoice não identificado")
      if (!this.fastTesourariaInvoice.id_plataforma) erros.push("Plataforma não identificada")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastChargeId.parcela = this.fastTesourariaInvoice.parcela
        this.fastChargeId.id_invoice = this.fastTesourariaInvoice.id_invoice
        this.fastChargeId.id_plataforma = this.fastTesourariaInvoice.id_plataforma
        this.$store.state.fastCarregando = true
        this.promisePostFastApi(this.fastChargeId, "api/fast_tesouraria_invoice_parcela/atualiza_charge_id")
          .then((res) => {
            console.log("atualiza_charge_id", res)
            let json = JSON.parse(res)
            if (!json.error) {            
              this.exibeToasty("Charge_id atualizado com sucesso", "success")   
              this.getParcelasInvoice(this.fastTesourariaInvoice.id_invoice)
              this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')
              this.fastChargeId = {
                charge_id: 0,
                parcela: 0,
                id_invoice: 0,
                id_plataforma: 0
              }
            } else {            
              if (json.description.split(",").length) {
                this.fastErros = json.description.split(",")
              } else {
                this.fastErros.push(json.description)
                console.log("this.fastErros", this.fastErros)
              }                          
            }
            this.getInconsistenciasGerenciaNet(this.fastTesourariaInvoice.id_plataforma, this.fastTesourariaInvoice.id_invoice, this.fastTesourariaInvoice.parcela)
            this.$store.state.fastCarregando = false
            
          })
          .catch((e) => {
            this.fastErros.push(e)
            this.$store.state.fastCarregando = false
          });
      }
      
    },
    retornaValorTotalParcela(){
      let total = 0
      if (this.fastTesourariaInvoice.valor_parcela) {
        total += parseFloat(this.fastTesourariaInvoice.valor_parcela)
      }
      if (this.fastTesourariaInvoice.acrescimo) {
        total += parseFloat(this.fastTesourariaInvoice.acrescimo)
      }
      if (this.fastTesourariaInvoice.taxa_boleto) {
        total += parseFloat(this.fastTesourariaInvoice.taxa_boleto)
      }
      if (this.fastTesourariaInvoice.taxa_cartao) {
        total += parseFloat(this.fastTesourariaInvoice.taxa_cartao)
      }
      if (this.fastTesourariaInvoice.descontos) {
        total -= parseFloat(this.fastTesourariaInvoice.descontos)
      }
      return this.formataPreco(total)
    },
    retornaLinkWhats(telefone, path_boleto, parcela, valor_parcela_total, data_vencimento, nome_plataforma, tipo){
      let link = ""
      let tel = telefone
      if (tel) {
        tel = telefone.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")
        tel = `55${tel}`
        if (path_boleto) {
          let text = path_boleto
          if (parcela && valor_parcela_total && data_vencimento && nome_plataforma && tipo) {
            let referente = ""
            if (tipo == "I") {
              referente = "à cobrança"
            } else if (tipo == "M"){
              referente = "à taxa de matrícula"
            } else if (tipo == "C"){
              referente = "ao curso definido"
            } else {
              referente = "à cobrança"
            }
            text = `Olá, segue a parcela ${parcela} do carnê no valor de R$ ${this.formataPreco(valor_parcela_total)}. Cobrança referente ${referente}, emitida por ${nome_plataforma}, com vencimento para ${this.formataDataT(data_vencimento)}. Acesse o boleto pelo link: ${path_boleto}`
          }
          link = `https://api.whatsapp.com/send?phone=${tel}&text=${text}`

        } else {
          this.exibeToasty("Boleto não cadastrado", "error")
        }
      } else {
        this.exibeToasty("Telefone não registrado", "error")
      }
      return link
    },
    copyURLBoleto() {
      const el = document.createElement('textarea');  
      el.value = this.fastTesourariaInvoice.path_boleto;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      this.fastLinkClipboard = true
    },
    fileValidation(id_invoice, parcela) {
      this.optionsArquivo.query.id_invoice = id_invoice
      this.optionsArquivo.query.parcela = parcela
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      
      if (!result.error) {
        console.log(result)
        this.fastTesourariaInvoice.path_comprovante_pagamento = result.url
        this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')
        this.exibeToasty("Comprovante salvo com sucesso", "success")
      } else {
        this.exibeToasty(result.error, "error")
      }
    },
    exibeModalConfirmarBaixaManual(){
      this.showModal('modalConfirmarBaixaManual') 
    },
    async getInconsistenciasGerenciaNet(id_plataforma, id_invoice, parcela){
      this.fastErrosGerencianetLoading = true
      this.fastErrosGerencianet = []
      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/verifica_inconsistencias", `id_plataforma=${id_plataforma}&id_invoice=${id_invoice}&parcela=${parcela}`)
      .then((res) => {
        let json = JSON.parse(res)
        this.fastPagtoIdentifiedGerencianet = json.identified_gerencianet
        
        if (json.erros) {
          if (json.erros.split(";").length) {
            this.fastErrosGerencianet = json.erros.split(";")
          } else {
            this.fastErrosGerencianet.push(json.erros)
          }
        }
        if (json.error) {
          this.fastErrosGerencianet.push(json.description)
        }
        this.fastErrosGerencianetLoading = false
        console.log("api/fast_tesouraria_invoice_parcela/verifica_inconsistencias", json)          
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastErrosGerencianetLoading = false
      })
    },
    //#endregion

    //#region Emitir boleto
    exibeModalEmitirBoleto(){
      this.alteraFastNavegacao('emitirBoleto')
      this.getUsuariosPlataforma(this.$route.params.id_plataforma)
    },
    // Primeiro passo
    async getUsuariosPlataforma(id_plataforma, perfil){
      this.fastPessoasLoading = true
      let param = `id_plataforma=${id_plataforma}&administrador=N&professor=N&aluno=N&tesouraria=N&secretario=N&responsavel=N`
      switch (perfil) {
        case 'administradores':
          param = `id_plataforma=${id_plataforma}&administrador=S&professor=&aluno=&tesouraria=&secretario=&responsavel=`
          break;
        case 'professores':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=S&aluno=&tesouraria=&secretario=&responsavel=`
          break;
        case 'alunos':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=S&tesouraria=&secretario=&responsavel=`
          break;
        case 'tesouraria':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=S&secretario=&responsavel=`
          break;
        case 'secretario':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=S&responsavel=`
          break;
        case 'responsavel':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=S`
          break;        
        default:
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=`
          break;
      }
      this.promiseGetFastApi("api/fast_plataforma_usuario/lista_usuarios_plataforma", param).then(obj => {
        console.log("getUsuariosPlataforma", obj)
        if (obj.length) {
          this.fastPessoasTotal = obj
          this.fastPessoasFiltro = obj
        }
        else {
          this.fastPessoasTotal = []
          this.fastPessoasFiltro = []
        }          
        this.fastPessoasLoading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar alunos", "error");
        this.fastPessoasLoading = false
      })
    },
    filtraAlunos(e) {
      let text = e.target.value;
      this.fastPessoasFiltro = this.fastPessoasTotal.filter((e) => {
        return e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },    
    verificaPessoaAdicionada(pessoa){
      let pessoaAdicionado = false
      this.fastSteps.step1.pessoas.forEach(e => {
        if (e.id_pessoa == pessoa.id_pessoa) pessoaAdicionado = true
      })
      return pessoaAdicionado
    },
    adicionaPessoa(pessoa) {
      // Restringe a somente uma pessoa por enquanto
      if (this.fastSteps.step1.pessoas.length) {
        this.exibeToasty("O sistema está restrito a somente uma pessoa", "error")
      } else {
        if (!this.verificaPessoaAdicionada(pessoa)) this.fastSteps.step1.pessoas.push(pessoa)   
      }
         
    },
    removePessoa(pessoa) {
      this.fastSteps.step1.pessoas = this.fastSteps.step1.pessoas.filter(p => p.id_pessoa != pessoa.id_pessoa)
    },
    retornaNomePessoas(){
      let nomes = "Aguardando..."
      let array = this.fastSteps.step1.pessoas.map(e => e.first_name + " " + e.last_name)
      if (array.length) nomes = array.join(", ")
      return nomes
    },
    async validaPrimeiroPasso(){
      return new Promise(async (resolve, reject) => {
        if (this.fastSteps.step1.pessoas.length) {
          this.getItensCobranca()
          resolve(true);
          return true;
        } else {
          this.exibeToasty("Adicione uma pessoa", "error");
          reject(true)
          return false
        }
      });
    },
    // Segundo passo
    async getItensCobranca(){      
      this.fastItensCobranca.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_itens_cobranca/lista", "id_plataforma=" + this.$route.params.id_plataforma).then(res => {
        console.log("getItensCobranca", res);
        if (res.length) this.fastItensCobranca.total = res;
        else this.fastItensCobranca.total = []

        this.fastItensCobranca.loading = false
      }).catch(e => {
        console.log(e);
        this.fastItensCobranca.loading = false
      })
    },
    async criaNovoItemCobranca(){
      let erros = []
      this.fastItemCobrancaNovo.id_plataforma = this.$route.params.id_plataforma
      if (!this.fastItemCobrancaNovo.nome_item) erros.push("O nome do item é obrigatório")
      if (!this.fastItemCobrancaNovo.valor_item) erros.push("O valor do item é obrigatório")
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
       this.promiseInserirFastApi(this.fastItemCobrancaNovo, "fast_tesouraria_itens_cobranca").then((res) => {
          console.log("criaNovoItemCobranca", res)
          if (res.length) {
            this.fastItemCobrancaNovo = {
              id_item_cobranca: 0,
              nome_item: "",
              valor_item: 0.00,
              quantidade: 1,
              id_plataforma: this.fastItemCobrancaNovo.id_plataforma
            }
            this.fastItensCobranca.total.push(res[0]);
            this.adicionaItemCobranca(res[0])
          } else {
            this.exibeToasty("Erro ao criar item de cobrança", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao criar item de cobrança", "error");
        });

        
      }      
    },
    alteraValorTotalItens(){
      this.fastSteps.step2.total = 0.00
      this.fastSteps.step2.itens.forEach((e) => {        
        if (!e.quantidade || e.quantidade == "0") {
          console.log("e.quantidade", e.quantidade)
          e.quantidade = 1
        }
        if (!e.valor_item) e.valor_item = 0.01
        this.fastSteps.step2.total += (e.valor_item * e.quantidade)
      });
      console.log("this.fastSteps.step2.total", this.fastSteps.step2.total)
    },
    verificaItemAdicionado(item){
      let itemAdicionado = false
      this.fastSteps.step2.itens.forEach(e => {
        if (e.id_item_cobranca == item.id_item_cobranca) itemAdicionado = true
      })
      return itemAdicionado
    },
    adicionaItemCobranca(item) {
      if (!this.verificaItemAdicionado(item))  {
        this.fastSteps.step2.itens.push(item)  
      }
      else {
        this.fastSteps.step2.itens.forEach((e) => {
          if (e.id_item_cobranca == item.id_item_cobranca) e.quantidade++
        });
      }

      this.alteraValorTotalItens()

      this.exibeToasty("Item adicionado", "success")
      this.hideModal('modalListaItensCobranca')      
    },
    removeItemCobranca(item){
      this.fastSteps.step2.itens = this.fastSteps.step2.itens.filter(p => p.id_item_cobranca != item.id_item_cobranca)
      this.alteraValorTotalItens()
    },   
    comissaoHabilitada(){
      let status = true
      this.fastSteps.step2.itens.forEach(e => {
        // Se for requerimento não pode aplicar comissão
        if (e.id_tipo_requerimento) status = false
      })
      return status
    },
    async validaSegundoPasso(){
      return new Promise(async (resolve, reject) => {
        if (this.fastSteps.step2.itens.length) {
          this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma)
          this.getSecretariaTipoPagamento(this.$route.params.id_plataforma)
          this.fastSteps.step3.plano.id_plano_pagamento = 0
          this.fastSteps.step3.tipo.id_tipo_pagamento = 0
          this.comissaoHabilitada()
          resolve(true);
          return true;
        } else {
          this.exibeToasty("Adicione um item de cobrança", "error");
          reject(true)
          return false
        }
      });
    },
    // Terceiro passo
    getSecretariaPlanoPagamento(id_plataforma){
      if (!this.fastSecretariaPlanoPagamento.length) {
        this.fastSteps.step3.plano.loading = true;
        this.promiseGetFastApi("api/fast_tesouraria_plano_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
          console.log("getSecretariaPlanoPagamento", obj);
          if (obj.length) {
            obj = obj.filter(p => {if (p.ativo) return true})
            this.fastSecretariaPlanoPagamento = obj;
          }
          else this.fastSecretariaPlanoPagamento = [];
          this.fastSteps.step3.plano.loading = false
        }).catch(e => {
          console.log(e);
          this.fastSteps.step3.plano.loading = false
        })
      }      
    },
    getSecretariaTipoPagamento(id_plataforma){
      if (!this.fastSecretariaTipoPagamento.length) {
        this.fastSteps.step3.tipo.loading = true;
        this.promiseGetFastApi("api/fast_tesouraria_tipo_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
          console.log("getSecretariaTipoPagamento", obj);
          if (obj.length) {
            // Filtra somente débito
            this.fastSecretariaTipoPagamento = obj.filter(p => { if (p.operador == 'D') return true });
            //this.fastSecretariaTipoPagamento = obj
          }
          else {
            this.fastSecretariaTipoPagamento = []
          }

          this.fastSteps.step3.tipo.loading = false;
        }).catch(e => {
          console.log(e);
          this.fastSteps.step3.tipo.loading = false;
        })
      }      
    },
    formataFormaPagamento(forma_pagamento) {
      switch (forma_pagamento) {
        case 'C':
          return 'Cartão'
          break;
        case 'B':
          return 'Boleto bancário'
          break;
        default:
          break;
      }
    },
    verificaConfiguracaoPagamento() {     
      if (this.fastSteps.step3.tipo.id_tipo_pagamento && this.fastSteps.step3.plano.id_plano_pagamento) {
        let erros = []

        // Pega o valor subtotal e total dos itens de cobraça
        if (!this.fastSteps.step2.total) erros.push("Valor total da fatura não identificado")

        // Busca o tipo selecionado
        this.fastSecretariaTipoPagamento.forEach(p => {
          if (p.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento) {
            this.fastSteps.step3.tipo.forma_pagamento = p.forma_pagamento
            this.fastSteps.step3.tipo.id_plano_conta = p.id_plano_conta
            this.fastSteps.step3.tipo.id_plataforma = p.id_plataforma
            this.fastSteps.step3.tipo.nome_tipo_pagamento = p.nome_tipo_pagamento
            this.fastSteps.step3.tipo.operador = p.operador
          }
        });
        if (!this.fastSteps.step3.tipo.forma_pagamento) erros.push("Tipo de pagamento não identificado")
        if (this.fastSteps.step3.tipo.forma_pagamento == "C") erros.push("O tipo de pagamento cartão de crédito não está ativado")

        // Busca o plano selecionado
        this.fastSecretariaPlanoPagamento.forEach(p => {
          if (p.id_plano_pagamento == this.fastSteps.step3.plano.id_plano_pagamento) {
            this.fastSteps.step3.plano.nome_plano = p.nome_plano
            this.fastSteps.step3.plano.obs = p.obs
            this.fastSteps.step3.plano.periocidade_cada_unidade = p.periocidade_cada_unidade
            this.fastSteps.step3.plano.dia_mes = p.dia_mes
            this.fastSteps.step3.plano.tempo_determinado = p.tempo_determinado
            this.fastSteps.step3.plano.parcelas = []
          }
        });
        if (!this.fastSteps.step3.plano.nome_plano) erros.push("Plano de pagamento não identificado")      
        if (!this.fastSteps.step3.plano.tempo_determinado || this.fastSteps.step3.plano.tempo_determinado < 1) erros.push("Plano de pagamento para tempo indeterminado não está ativo")  

        if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastSteps.step3.plano.id_plano_pagamento = 0
          this.fastSteps.step3.tipo.id_tipo_pagamento = 0
        } else {
          this.aplicaAjusteValor()
        }
      }
    },    
    aplicaAjusteValor(){
      let erros = []

      // Ajustes do valor total, o subtotal nunca é alterado
      let valor_total = 0.00
      valor_total = this.fastSteps.step2.total
      this.fastSteps.step3.invoice.subtotal = valor_total

      if (this.fastSteps.step3.invoice.acrescimo == "") this.fastSteps.step3.invoice.acrescimo = 0.00
      if (this.fastSteps.step3.invoice.desconto_total == "") this.fastSteps.step3.invoice.desconto_total = 0.00
      valor_total = parseFloat(this.fastSteps.step3.invoice.subtotal) + parseFloat(this.fastSteps.step3.invoice.acrescimo) - parseFloat(this.fastSteps.step3.invoice.desconto_total)      
      
      let n_parcelas = 0
      n_parcelas = this.fastSteps.step3.plano.tempo_determinado / this.fastSteps.step3.plano.periocidade_cada_unidade 
      
      // Dessa forma arredonda pra cima
      let valor_parcela = parseFloat(valor_total / n_parcelas)      
      valor_parcela = this.arredondaCasasDecimais(valor_parcela)  

      // Dessa forma gera incremento pra ser adicionado na primeira parcela 
      //let valor_parcela = parseFloat(valor_total / n_parcelas).toFixed(2)   
      //let parcelasSomadas = n_parcelas * parseFloat(valor_parcela)
      //let incremento = 0.00

      // Se existir taxas nas parcelas incrementa no valor total
      let taxa_boleto = 0.00
      if (this.fastSteps.step3.invoice.taxa_boleto) taxa_boleto = parseFloat(this.fastSteps.step3.invoice.taxa_boleto)
      let taxa_cartao = 0.00
      if (this.fastSteps.step3.invoice.taxa_cartao) taxa_cartao = parseFloat(this.fastSteps.step3.invoice.taxa_cartao)              
      let outras_taxas = 0.00
      if (this.fastSteps.step3.invoice.outras_taxas) outras_taxas = parseFloat(this.fastSteps.step3.invoice.outras_taxas)
      let taxas_parcela = (taxa_boleto * n_parcelas) + (taxa_cartao * n_parcelas) + (outras_taxas * n_parcelas)

      this.fastSteps.step3.invoice.total = (valor_parcela * n_parcelas) + taxas_parcela   
            
      // Se a soma das parcelas não bater com o valor total original então é necessário calcular um incremento para adicionar no valor total e posteriormente na primeira parcela
      //if (parseFloat(this.fastSteps.step2.total) > parseFloat(parcelasSomadas).toFixed(2)) {
      //  incremento = parseFloat(this.fastSteps.step2.total) - parseFloat(parseFloat(parcelasSomadas).toFixed(2))
      //  this.fastSteps.step3.invoice.total += incremento
      //} 

      // O mínimo valor da parcela é 5 (restrição do gerencianet)
      if (valor_parcela < 5) erros.push("O valor mínimo da parcela é de R$ 5,00")

      // Se o plano possui tempo determinado
      if (!this.fastSteps.step3.plano.tempo_determinado) erros.push("Plano sem tempo determinado não é suportado no momento")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
        this.fastSteps.step3.plano.id_plano_pagamento = 0
        this.fastSteps.step3.tipo.id_tipo_pagamento = 0
      } else {
         let dataAtual = new Date()
          // Captura a data do servidor para cálculo das datas das parcelas
          this.getFastHoraServidor()
          .then(() => {
            // Evita problema de fevereiro
            dataAtual = this.$store.state.fastDataAtualServidor          
            if (dataAtual.getDate() > 28) { 
              dataAtual.setMonth(dataAtual.getMonth() + 1)
              dataAtual.setDate(1)
            } 
            if (this.dataPrimeiraParcela) dataAtual = new Date(this.dataPrimeiraParcela.split("-")[0] + "/" + this.dataPrimeiraParcela.split("-")[1] + "/" + this.dataPrimeiraParcela.split("-")[2])
            return dataAtual
          })
          .then(dataAtual => {    
              // Monta as parcelas
              this.fastSteps.step3.plano.parcelas = []
              this.fastSteps.step3.invoice.parcelas = []
              for (let index = 0; index < n_parcelas; index++) {

                // Se periodicidade for mês seta do vencimento por mês
                if (this.fastSteps.step3.plano.dia_mes == "M") {
                  if (index) {       
                      dataAtual.setMonth( dataAtual.getMonth() + this.fastSteps.step3.plano.periocidade_cada_unidade );  
                  }
                // Se periodicidade for dia seta do vencimento por dia
                } else if (this.fastSteps.step3.plano.dia_mes == "D") {
                  if (index) dataAtual = this.addDays(dataAtual, this.fastSteps.step3.plano.periocidade_cada_unidade );
                }

                // Cria objeto da parcela
                let objParcela = {
                  parcela: index + 1,
                  total: this.fastSteps.step3.invoice.total,
                  valor_parcela: valor_parcela,
                  datetime: dataAtual,
                  data_formatada: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                  data_vencimento: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                  acrescimo: 0.00,
                  desconto: 0.00,
                  taxa_boleto: taxa_boleto,
                  taxa_cartao: taxa_cartao,
                  outras_taxas: outras_taxas,
                  nr_parcelas: n_parcelas                  
                } 

                // Se existir incremento adiciona na primeira parcela
                //if (incremento > 0 && index == 0)   objParcela.valor_parcela = parseFloat(valor_parcela) + parseFloat(incremento)
                
                // Adiciona objeto parcela no array de parcelas do plano e invoice
                this.fastSteps.step3.plano.parcelas.push(objParcela)
                this.fastSteps.step3.invoice.parcelas.push(objParcela)

              }

              // Chama as comissões novamente
              this.getComissaoSchemas(this.$route.params.id_plataforma)
          })
          .catch((e) => this.exibeToasty(e, "error"))
      }
    },
    definePrimeiraParcela() {
      if (this.dataPrimeiraParcela.split("-").length) {
        this.aplicaAjusteValor()
        this.hideModal("modalDefinirPrimeiraParcela")
      }      
    },
    getInvoiceGerado(id_plataforma, id_invoice, parcela){
      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_invoice_gerado", `id_plataforma=${id_plataforma}&id_invoice=${id_invoice}`).then(res => {
        let json = JSON.parse(res)
        if (!json.error) {
          if (parcela) {
            json.forEach(e => {
              if (e.parcela == parcela) {
                this.exibeModalEditarBoleto(e)
              }
            })
          } else {
            this.exibeModalEditarBoleto(json[0])
          }          
        } else {
          if (json.description.split(",").length) {
            this.fastErros = json.description.split(",")
          } else {
            this.fastErros.push(json.description)
            console.log("this.fastErros", this.fastErros)
          }
          this.showModal("modalErrosRequisicao")
        }
      }).catch(e => {
        console.log(e);
        this.exibeToasty(e, "error")
      })
    },
    async validaTerceiroPasso(){
      return new Promise(async (resolve, reject) => {
        if (this.fastSteps.step3.plano.id_plano_pagamento && this.fastSteps.step3.tipo.id_tipo_pagamento) {
          this.fastSecretariaTipoPagamento.forEach(t => {
            if (t.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento) {
              this.fastSteps.step3.tipo.nome_tipo_pagamento = t.nome_tipo_pagamento
              this.fastSteps.step3.tipo.forma_pagamento = t.forma_pagamento
            }
          })

          // Valida pessoa
          this.fastSteps.step1.pessoas.forEach((p, index) => {
            if (!p.id_pessoa) {
              this.exibeToasty(`O usuário ${p.first_name} não possui um id_pessoa`, "error")
            } else {
              console.log("api/fast_plataforma_usuario/busca_pessoa_secretaria", p.id_pessoa)

              this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + p.id_pessoa + "&id_plataforma=" + this.$route.params.id_plataforma).then(obj => {       
                let objPessoa = JSON.parse(obj)
                console.log("busca_pessoa_secretaria -> resposta", objPessoa)
                if (objPessoa.id_pessoa) {
                  objPessoa.id_plataforma = this.$route.params.id_plataforma

                  let erros = []

                  if (objPessoa.cpf_cnpj) p.cpf_cnpj = objPessoa.cpf_cnpj
                  else 
                    erros.push(`O usuário ${p.first_name} não possui CPF/CNPJ cadastrado`)
                  
                  if (objPessoa.cep) p.cep = objPessoa.cep
                  else 
                    erros.push(`O usuário ${p.first_name} não possui endereço completo cadastrado`)

                  if (objPessoa.datanascimento_abertura) p.datanascimento_abertura = objPessoa.datanascimento_abertura
                  else 
                    erros.push(`O usuário ${p.first_name} não possui a data de nascimento cadastrada`)

                  if (objPessoa.email) p.email = objPessoa.email
                  else 
                    erros.push(`O usuário ${p.first_name} não possui email cadastrado`)

                  if (objPessoa.telefone) p.telefone = objPessoa.telefone
                  else 
                    erros.push(`O usuário ${p.first_name} não possui telefone cadastrado`)

                  if (objPessoa.complemento) p.complemento = objPessoa.complemento
                  if (objPessoa.numero) p.numero = objPessoa.numero.trim()                  

                  this.fastSteps.step3.invoice.cpf_responsavel = p.cpf_cnpj,
                  this.fastSteps.step3.invoice.cep_responsavel = p.cep,
                  this.fastSteps.step3.invoice.endereco_responsavel_numero = p.numero,
                  this.fastSteps.step3.invoice.endereco_responsavel_complemento = p.complemento,
                  this.fastSteps.step3.invoice.ativo = true
                  this.fastSteps.step3.invoice.cpf_aluno = p.cpf_cnpj
                  this.fastSteps.step3.invoice.id_pessoa_aluno = p.id_pessoa   
                  this.fastSteps.step3.invoice.id_pessoa_responsavel = p.id_pessoa
                  this.fastSteps.step3.invoice.id_plano_pagamento = this.fastSteps.step3.plano.id_plano_pagamento
                  this.fastSteps.step3.invoice.id_plataforma = this.$route.params.id_plataforma
                  this.fastSteps.step3.invoice.nome_aluno = p.first_name + " " + p.last_name
                  this.fastSteps.step3.invoice.nome_responsavel = p.first_name + " " + p.last_name

                  // Gerencianet
                  this.fastSteps.step3.invoice.id_gateway = 2

                  if (erros.length) {
                    erros.forEach(e => this.exibeToasty(e, "error"))
                    erros = []
                    reject(false)
                    return false;
                  } else {

                    let obj = {
                      fast_pessoa: objPessoa,
                      fast_tesouraria_invoice: this.fastSteps.step3.invoice,
                      fast_tesouraria_invoice_parcela: [],
                      fast_tesouraria_invoice_itens_cobranca: this.fastSteps.step2.itens,
                      fast_usuario: {
                        email: p.email
                      },
                      fast_pessoa_telefone: {
                        telefone: p.telefone
                      }
                    }

                   this.fastSteps.step3.plano.parcelas.forEach((parcela, index) => {                    
                      let fast_tesouraria_invoice_parcela = {
                        id_invoice: 0,
                        parcela: index + 1,
                        valor_parcela: parcela.valor_parcela,
                        acrescimo: parcela.acrescimo,
                        desconto: parcela.desconto,
                        total: parcela.total,
                        valor_pago: 0.00,
                        taxa_boleto: parcela.taxa_boleto,
                        taxa_cartao: parcela.taxa_cartao,
                        outras_taxas: parcela.outras_taxas,
                        //data_vencimento: parcela.data_vencimento.split("/")[1] + "/" + parcela.data_vencimento.split("/")[0] + "/" + parcela.data_vencimento.split("/")[2],
                        data_vencimento: parcela.data_vencimento.split("/")[2].replace(',', '') + "-" + parcela.data_vencimento.split("/")[1] + "-" + parcela.data_vencimento.split("/")[0],
                        path_boleto: null,
                        id_gateway: this.fastSteps.step3.invoice.id_gateway,
                        autenticacao: null,
                        pago: false,
                        log_pagto_auditoria: null,
                        id_tipo_pagamento: this.fastSteps.step3.tipo.id_tipo_pagamento,
                        nr_parcelas: this.fastSteps.step3.plano.parcelas.length
                      }
                      obj.fast_tesouraria_invoice_parcela.push(fast_tesouraria_invoice_parcela)

                    });

                    console.log("obj", obj)

                    this.$store.state.fastCarregando = true
                    this.promisePostFastApi(obj, "api/fast_tesouraria_invoice/insere_item_cobranca")
                    .then(res => {                
                      if (res.length){
                        const json = JSON.parse(res)
                        // Última pessoa
                        if (this.fastSteps.step1.pessoas.length == (index + 1)) {
                          if (json.id_invoice) {   
                            this.fastTesourariaInvoice.id_invoice = json.id_invoice                         
                            resolve(true);
                            return true;
                          } else {
                            if (json.description.split(",").length) {
                              this.fastErros = json.description.split(",")
                            } else {
                              this.fastErros.push(json.description)
                              console.log("this.fastErros", this.fastErros)
                            }
                            this.$store.state.fastCarregando = false
                            this.showModal('modalErrosRequisicao')
                          }
                          
                        }                        
                      }
                    })
                    .catch((e) => {                
                      this.exibeToasty(`${e}`, "error")
                      this.$store.state.fastCarregando = false
                      reject(false)
                      return false;
                    });

                    
                  }                  

                } else {
                  this.exibeToasty(`Erro ao buscar informações do usuário ${p.first_name}`, "error")
                  reject(false)
                  return false;
                }
              })
              .catch((e) => {                
                this.exibeToasty(`${e}`, "error")
                reject(false)
                return false;
              });
            }
          });      

          
        } else {
          this.exibeToasty("Escolha um plano e um tipo de pagamento", "error");
          reject(true)
          return false
        }        
      });
    },
    async onComplete() {
      return new Promise(async (resolve, reject) => {  

        this.getFastHoraServidor()
        .then(() => {
              this.$store.state.fastCarregando = false
              this.exibeToasty("Fatura gerada com sucesso", "success")        
              this.getInvoiceGerado(this.$route.params.id_plataforma, this.fastTesourariaInvoice.id_invoice)
              resolve(true);
              return true;
        })
        .catch((e) => {
          this.exibeToasty(e, "error")
          reject(false)
          return false;
        })
        

      });
    },    
    handleValidation: function (isValid, tabIndex) {
       console.log("Tab: " + tabIndex + " valid: " + isValid)
    },
    handleErrorMessage: function (errorMsg) {
      console.log(errorMsg)
    },
    // Comissao
    async getComissaoSchemas(id_plataforma){   
      this.fastComissaoSchemaDeterminacoes.total = [] 
      this.fastComissaoSchemaDeterminacoes.loading = false

      this.fastComissaoSchema.total = []
      this.fastComissaoSchema.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_comissao_schema/lista", "id_plataforma=" + id_plataforma).then(res => {
        if (res.length) {
          this.fastComissaoSchema.total = res
        } else {
          this.fastComissaoSchema.total = []
        }
        this.fastComissaoSchema.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastComissaoSchema.loading = false
      })
    },
    async getComissaoSchemaDeterminacoes(e){     
      let id_comissao_schema = e.target.value

      if (id_comissao_schema > 0) {
        this.fastComissaoSchemaDeterminacoes.total = [] 
        this.fastComissaoSchemaDeterminacoes.loading = true
        this.promiseGetFastApi("api/fast_tesouraria_comissao_schema_determinacao/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_comissao_schema=" + id_comissao_schema)
        .then(res => {
          if (res.length) {
            let valida_curso = true
            res.forEach(obj => {
              // Schema de curso da secretaria não pode ser selecionado
              if (obj.id_secretaria_curso) valida_curso = false

              if (obj.id_curso) {       
                // Schema com curso livre deve ser o do item de cobrança          
                this.fastSteps.step2.itens.forEach(e => {
                  if (e.id_curso) {
                    if (obj.id_curso != e.id_curso) {
                      valida_curso = false
                    }
                  }                  
                })
              }

              obj.pessoas = []
              
              obj.valor_comissao_parcela = 0
              obj.valor_comissao_total = 0
              if (this.fastSteps.step3.invoice.parcelas.length) {
                let valor_parcela = this.fastSteps.step3.invoice.parcelas[0].valor_parcela
                if (obj.percentual) {
                  obj.valor_comissao_parcela = (valor_parcela * obj.percentual) / 100
                  obj.valor_comissao_total = this.fastSteps.step3.invoice.parcelas.length * obj.valor_comissao_parcela
                } else if (obj.valor) {
                  obj.valor_comissao_parcela = obj.valor
                  obj.valor_comissao_total = this.fastSteps.step3.invoice.parcelas.length * obj.valor_comissao_parcela
                }
              }
              
            })

            if (valida_curso) {
              this.fastComissaoSchemaDeterminacoes.total = res
                this.fastComissaoSchemaDeterminacoes.total.forEach(obj => {
                  if (obj.id_funcao_pessoa) {
                    this.getPessoasPorFuncao(obj)
                  }
                })
            } else {
              this.exibeToasty("Schema de comissão não aplicável a este curso", "error")
            }
            
          }
          this.fastComissaoSchemaDeterminacoes.loading = false
        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastComissaoSchemaDeterminacoes.loading = false
        })
      } else {
        this.fastComissaoSchemaDeterminacoes.total = [] 
        this.fastComissaoSchemaDeterminacoes.loading = false
      }
      
    },
    async getPessoasPorFuncao(obj){    
      this.promiseGetFastApi("api/fast_pessoa_funcao/lista_funcao", "id_plataforma=" + this.$route.params.id_plataforma + "&id_funcao_pessoa=" + obj.id_funcao_pessoa)
      .then(res => {        
        if (res.length) {
          obj.pessoas = res
        } 
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
      })
    },
    //#endregion
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-cursor-refresh {
  cursor: pointer;
}

.vue-form-wizard .wizard-tab-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 992px) {
   
  .wizard-nav-pills li {
    flex-basis: 100% !important;
  }
  .wizard-progress-with-circle, .wizard-nav-pills, .wizard-header {
    display: none !important;
  }
}
</style>
